/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/home.mp4";
import bgImage2 from "assets/images/home_bg2.png";
import bgImage3 from "assets/images/home_bg3.png";

// import defaultImage from "assets/images/default.png";
// import quotes from "assets/images/quotes.png";

import graph from "assets/images/home_graph.png";
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import ScollCounterCard from "examples/Cards/CounterCards/ScrollCounterCard";
import TextLoop from "react-text-loop";
import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "examples/Footers/Footer";
import footerRoutes from "footer.routes";
import TopMenu from "pages/Componets/topMenu";
// import MKAvatar from "components/MKAvatar";
// import { useEffect } from "react";
// import { useMst } from "models";

function Presentation() {
  return (
    <MKBox>
      <video
        height="100%"
        id="myvideo"
        autoPlay
        muted
        loop
        style={{
          backgroundColor: "black",
          width: "100%",
          height: "100%",
          position: "absolute",
          right: 0,
          minWdth: "100%",
          height: "100%",
        }}
      >
        <source src={bgImage} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <TopMenu />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={0}>
            <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={0}>
              <MKTypography variant="h1" color="white" textAlign="left" mb={1}>
                자기다움을
                <br />
                읽어드립니다.{" "}
              </MKTypography>
            </Grid>
            <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={0}>
              <MKTypography variant="h4" color="white" textAlign="left" mb={1}>
                삶이란 여정의 목적은
                <br />
                우리안에 실현되지 않은
                <br />
                위대함을 세상 속으로 가져오는 것{" "}
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={3} style={{ backgroundColor: "#181717" }}>
        <Container>
          <MKBox textAlign="center" justifyContent="center">
            <MKTypography variant="h3" color="secondary" textAlign="center" mt={25}>
              진단시간 10분이면 충분해요.{" "}
            </MKTypography>
            <MKTypography variant="h5" color="white" textAlign="center" mt={5}>
              진단시간 10분이면 『자기다움 사용설명서』를 받아 볼 수 있어요{" "}
            </MKTypography>
            <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mb={25}>
              <MKBox
                mt={10}
                component="div"
                justifyContent="space-between"
                display="flex"
                sx={{
                  width: "463px",
                  height: "120px",
                  color: "#287361",
                  backgroundColor: "#030303",
                  borderRadius: "4px",
                  border: "1px solid #03FF99",
                }}
              >
                <MKTypography variant="h5" color="white" textAlign="left" mt={4} ml={3}>
                  자기다움을
                  <br />
                  발견한 사람.{" "}
                </MKTypography>
                <MKBox mt={1} mr={1}>
                  <ScollCounterCard count={10869} suffix="명" color="secondary" />
                </MKBox>
              </MKBox>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
      <MKBox
        component="section"
        py={3}
        sx={{
          backgroundImage: `url(${bgImage2})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto">
          <MKBox
            mt={15}
            component="div"
            justifyContent="space-between"
            display="flex"
            style={{
              color: "#287361",
              backgroundColor: "#333232",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <MKTypography color="secondary" textAlign="center" style={{ fontSize: "14px" }}>
              기질유형{" "}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={7}>
          <MKTypography variant="h5" color="secondary" textAlign="center" mr={2}>
            내가 바로{" "}
          </MKTypography>
          <MKBox
            component="div"
            justifyContent="center"
            display="flex"
            style={{
              width: "76px",
              color: "#287361",
              border: "1px solid #03FF99",
              borderRadius: "2px",
              padding: "3px",
              marginTop: "-3px",
            }}
          >
            <TextLoop interval={3000}>
              <MKTypography variant="h5" color="secondary" textAlign="center">
                행동가
              </MKTypography>
              <MKTypography variant="h5" color="secondary" textAlign="center">
                상담가
              </MKTypography>
              <MKTypography variant="h5" color="secondary" textAlign="center">
                행동가
              </MKTypography>
              <MKTypography variant="h5" color="secondary" textAlign="center">
                분석가
              </MKTypography>
            </TextLoop>
          </MKBox>
          <MKTypography variant="h5" color="secondary" textAlign="center" ml={2} mr={2}>
            라인의{" "}
          </MKTypography>
          <MKBox
            component="div"
            justifyContent="center"
            display="flex"
            style={{
              width: "76px",
              color: "#287361",
              backgroundColor: "#03FF99",
              borderRadius: "2px",
              padding: "3px",
              marginTop: "-3px",
            }}
          >
            <TextLoop interval={1500}>
              <MKTypography variant="h5" color="dark" textAlign="center">
                창조가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                안내자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                승부사
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                장인
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                상담가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                촉진자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                대변인
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                조직자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                행동가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                혁명가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                선도자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                보호자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                분석가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                쇄신가
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                조정자
              </MKTypography>
              <MKTypography variant="h5" color="dark" textAlign="center">
                개발자
              </MKTypography>
            </TextLoop>
          </MKBox>
          <MKTypography variant="h5" color="secondary" textAlign="center" ml={2}>
            유형{" "}
          </MKTypography>
        </Grid>
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={4}>
          <MKTypography variant="h6" color="white" textAlign="center">
            나는 16 HERO TYPE 중 어디에 속할까?{" "}
          </MKTypography>
        </Grid>
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={5}>
          <MKBox component="img" src={graph} />
        </Grid>
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={15}>
          <MKTypography variant="h5" color="white" textAlign="center" mt={0}>
            『자기다움 사용설명서』에는{" "}
          </MKTypography>
        </Grid>
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={1} mb={100}>
          <MKTypography variant="h5" color="white" textAlign="center" mt={0}>
            이런 것들을 읽어드려요{" "}
          </MKTypography>
        </Grid>
      </MKBox>
      <MKBox
        component="section"
        py={3}
        sx={{
          backgroundImage: `url(${bgImage3})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
          container
          item
          xs={6}
          lg={6}
          justifyContent="center"
          mx="auto"
          mt={70}
          mb={10}
          component={Link}
          to="/icru"
          // onClick={() => {  }}
        >
          <MKTypography variant="h5" color="secondary" textAlign="center" mt={0}>
            ICRU 기질진단 이란?{" "}
          </MKTypography>
          <MKBox ml={1}>
            <Icon fontSize="medium" color="secondary">
              trending_flat_outlined
            </Icon>
          </MKBox>
        </Grid>
      </MKBox>
      {/* <MKBox
        component="section"
        py={3}
        sx={{
          backgroundColor: "#010101",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto">
          <MKBox
            mt={10}
            component="div"
            justifyContent="space-between"
            display="flex"
            style={{
              color: "#287361",
              backgroundColor: "#333232",
              borderRadius: "4px",
              padding: "10px",
            }}
          >
            <MKTypography color="secondary" textAlign="center" style={{ fontSize: "14px" }}>
              자기다움을 발견한 사람들{" "}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
          <MKBox textAlign="center">
            <MKBox
              mt={10}
              sx={{
                width: "373px",
                height: "343px",
                backgroundColor: "#181717",
                textAlign: "center",
                padding: "30px",
              }}
            >
              <MKBox component="img" src={quotes} width={50} />
              <MKTypography variant="h6" textAlign="center" mt={3} style={{ color: "#F4F4F4" }}>
                30분 동안 정독 했습니다. 취업 준비를 하다보니… 자존감도 많이 떨어지고 진짜 나는
                누구일까… 혼란스러웠습니다. ICRU기질진단 테스트를 통해 제가 누구인지 이해할 수 있는
                계기가 되었고 또 어떻게 살아가야 하는지에 대한 미션들도 있어 실마리를 얻은
                기분이었습니다.
              </MKTypography>
            </MKBox>
            <MKBox mt={{ xs: -5, md: -5 }} textAlign="center">
              <MKAvatar src={defaultImage} alt="Burce Mars" size="xxl" shadow="xl" />
            </MKBox>
          </MKBox>
          <MKBox textAlign="center">
            <MKBox
              mt={10}
              sx={{
                width: "373px",
                height: "343px",
                backgroundColor: "#03FF99",
                textAlign: "center",
                padding: "30px",
              }}
            >
              <MKTypography variant="h6" color="dark" textAlign="center" mt={3}>
                <MKBox component="img" src={quotes} width={50} style={{ opacity: 0.2 }} />
                30분 동안 정독 했습니다. 취업 준비를 하다보니… 자존감도 많이 떨어지고 진짜 나는
                누구일까… 혼란스러웠습니다. ICRU기질진단 테스트를 통해 제가 누구인지 이해할 수 있는
                계기가 되었고 또 어떻게 살아가야 하는지에 대한 미션들도 있어 실마리를 얻은
                기분이었습니다.
              </MKTypography>
            </MKBox>
            <MKBox mt={{ xs: -5, md: -5 }} textAlign="center">
              <MKAvatar src={defaultImage} alt="Burce Mars" size="xxl" shadow="xl" />
            </MKBox>
          </MKBox>
          <MKBox textAlign="center">
            <MKBox
              mt={10}
              sx={{
                width: "373px",
                height: "343px",
                backgroundColor: "#181717",
                textAlign: "center",
                padding: "30px",
              }}
            >
              <MKBox component="img" src={quotes} width={50} />
              <MKTypography variant="h6" textAlign="center" mt={3} style={{ color: "#F4F4F4" }}>
                30분 동안 정독 했습니다. 취업 준비를 하다보니… 자존감도 많이 떨어지고 진짜 나는
                누구일까… 혼란스러웠습니다. ICRU기질진단 테스트를 통해 제가 누구인지 이해할 수 있는
                계기가 되었고 또 어떻게 살아가야 하는지에 대한 미션들도 있어 실마리를 얻은
                기분이었습니다.
              </MKTypography>
            </MKBox>
            <MKBox mt={{ xs: -5, md: -5 }} textAlign="center">
              <MKAvatar src={defaultImage} alt="Burce Mars" size="xxl" shadow="xl" />
            </MKBox>
          </MKBox>
        </Grid>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={20}>
          <MKBox
            textAlign="center"
            justifyContent="center"
            component={Link}
            p={2}
            style={{
              color: "#00FF94",
              width: "270px",
              height: "80px",
              borderRadius: "4px",
              border: "1px solid #00FF94",
            }}
          >
            <Icon>account_circle_outlined_icon</Icon>
            <MKTypography variant="h5" style={{ color: "#00FF94" }} fontSize="small">
              기업 서비스 바로가기
            </MKTypography>
          </MKBox>
          <MKBox
            textAlign="center"
            justifyContent="center"
            component={Link}
            p={2}
            ml={2}
            style={{
              color: "#00FF94",
              width: "270px",
              height: "80px",
              borderRadius: "4px",
              border: "1px solid #00FF94",
            }}
          >
            <Icon>corporate_fare_outlined_icon</Icon>
            <MKTypography variant="h5" style={{ color: "#00FF94" }} fontSize="small">
              학교 서비스 바로가기
            </MKTypography>
          </MKBox>
          <MKBox
            textAlign="center"
            justifyContent="center"
            component={Link}
            p={2}
            ml={2}
            style={{
              color: "#00FF94",
              width: "270px",
              height: "80px",
              borderRadius: "4px",
              border: "1px solid #00FF94",
            }}
          >
            <Icon>school_outlined_icon</Icon>
            <MKTypography variant="h5" style={{ color: "#00FF94" }} fontSize="small">
              가맹점 서비스 바로가기
            </MKTypography>
          </MKBox>
        </Grid>
        <MKBox mb={20} />
      </MKBox> */}
      <MKBox pt={6} px={1} mt={0} style={{ backgroundColor: "#03FF99", justifyContent: "center" }}>
        <Footer content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default Presentation;
