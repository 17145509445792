import { types } from "mobx-state-tree";
import { industry, jobGroup } from "../utils/industry";

// console.log(industry, jobGroup);

export const User = types
  .model("User", {
    id: types.maybe(types.integer),
    userId: types.maybe(types.string),
    type: types.maybe(types.string),
    email: types.maybe(types.string),
    name: types.maybe(types.string),
    gender: types.maybe(types.string),
    birthYear: types.maybe(types.integer),
    industry: types.maybe(types.string),
    jobGroup: types.maybe(types.string),
    agency: types.maybe(types.string),
    rank: types.maybe(types.string),
    profile: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    updatedAt: types.maybe(types.string),
    companyName: types.maybe(types.string),
    companyId: types.maybe(types.number),
    teamName: types.maybe(types.string),
    teamId: types.maybe(types.number),
    independent: types.maybe(types.boolean),
    point: types.maybe(types.number),
    phone: types.maybe(types.string),
  })
  .views((self) => ({
    get me() {
      return {
        ...self,
      };
    },
  }))
  .actions((self) => ({
    async setData(data) {
      console.log("setData", data);
      self.id = data?.id || 0;
      self.userId = data?.userId || "";
      self.type = data?.type || "";
      self.email = data?.email || "";
      self.name = data?.name || "";
      self.gender = data?.gender || "";
      self.birthYear = data?.birthYear || 0;
      self.industry = data?.industry ? industry[data?.industry] : "";
      self.jobGroup =
        data?.industry && data?.jobGroup ? jobGroup[data?.industry][data?.jobGroup] : "";
      self.agency = data?.agency || "";
      self.rank = data?.rank || "";
      self.profile = data?.profile || "";
      self.companyName = data?.company?.name || "";
      self.companyId = data?.company?.id || 0;
      self.teamName = data?.team?.name || "";
      self.teamId = data?.team?.id || 0;
      self.independent = data?.independent || false;
      self.createdAt = data?.createdAt || "";
      self.updatedAt = data?.updatedAt || "";
      self.point = data?.point || 0;
      self.string = data?.string || "";

      if (data?.company?.companyId === "gangnamopk" || data?.company?.companyId === "test") {
        self.independent = true;
      }
      console.log("self", self);
    },
    async logout() {
      self.id = 0;
      self.userId = "";
      self.type = "";
      self.email = "";
      self.name = "";
      self.gender = "";
      self.birthYear = 0;
      self.industry = "";
      self.jobGroup = "";
      self.agency = "";
      self.companyName = "";
      self.companyId = 0;
      self.teamName = "";
      self.teamId = 0;
      self.independent = false;
      self.profile = "";
      self.createdAt = "";
      self.updatedAt = "";
      self.point = 0;
      self.phone = "";
    },
  }));

export default User;
