/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import brandImage from "assets/images/brand_dark.png";

// import pIcon from "assets/images/p.png";
// import cIcon from "assets/images/c.png";
// import sIcon from "assets/images/s.png";
// import gIcon from "assets/images/g.png";

import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { AppBar, Container, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import AuthService from "services/AuthService";
import { getItem } from "utils/localstorage";

const UserInfo = () => {
	const navigate = useNavigate();
	const location = useLocation();

  const [userData, setUserData] = useState({
    name: "",
    birthYear: "",
    gender: 0,
    phone: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    birthYear: false,
    gender: 0,
    phone: false,
    email: false,
  });

  useEffect(() => {
  }, []);

  const handleTabType = (event, newValue) => setUserData({ ...userData, gender: newValue });

  const signUp = async () => {
    console.log(userData);
    if (!userData.name) {
      toast.error("이름을 입력해주세요.");
      return;
    }
    if (!userData.birthYear) {
      toast.error("출생년도를 입력해주세요.");
      return;
    }
    if (userData.gender < 0) {
      toast.error("성별을 선택해주세요.");
      return;
    }
    if (!userData.phone) {
      toast.error("전화번호를 입력해주세요.");
      return;
    }
    if (!userData.email) {
      toast.error("이메일을 입력해주세요.");
      errors.email = true
      return;
    } else {
			userData.companyId = window.location?.hostname?.replace('.icru.co.kr', '')
			userData.code = location.state?.code

      AuthService.startUp(userData).then((res) => {
        console.log(res);
        if (res?.code === 200) {
          if (res.data.result) {
            toast.success("진단을 시작 합니다..");
            navigate('/question')
          } else {
            toast.success("이미 진단을 완료 하였습니다.");
          }
        } else {
          toast.error("진단시작 실패하였습니다. 새로고침 후 다시 시작하세요.");
        }
      });
    }
  };

  const isExistEmail = (e) => {
    setUserData({ ...userData, email: e.target.value });
  };

  return (
    <>
      <MKBox position="absolute" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={4} md={4} lg={4} xl={4}>
              <Card>
                <MKBox mx={2} p={2} mb={1} textAlign="center">
                  <MKTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                    누구이신가요?
                  </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                  <MKBox component="form" role="form">
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label={"이름*"}
                        placeholder={"이름*"}
                        fullWidth
                        value={userData.name}
                        onInput={(e) => setUserData({ ...userData, name: e.target.value })}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                    <MKInput
                        type="number"
                        label="출생년도*"
                        placeholder="출생연도를 입력해 주세요.(ex 1992)"
                        fullWidth
                        value={userData.birthYear}
                        onInput={(e) => setUserData({ ...userData, birthYear: e.target.value })}
                    />
                    </MKBox>
                    <MKBox mb={2}>
                    <AppBar position="static">
                        <Tabs value={userData.gender} onChange={handleTabType}>
                        <Tab
                            icon={
                            <MKBox
                                component="i"
                                color="dark"
                                mr={1.25}
                                sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                                className="fas fa-male"
                            />
                            }
                            label="남"
                        />
                        <Tab
                            icon={
                            <MKBox
                                component="i"
                                color="dark"
                                mr={1.25}
                                sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                                className="fas fa-female"
                            />
                            }
                            label="여"
                        />
                        </Tabs>
                    </AppBar>
                    </MKBox>
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label="전화번호*"
                        placeholder="전화번호를 입력해 주세요.(- 없이)"
                        style={{ width: "100%" }}
                        value={userData.phone}
                        onInput={(e) =>
                          setUserData({
                            ...userData,
                            phone: e.target.value.replace(/[^0-9]/gi, ""),
                          })
                        }
                      />
                    </MKBox>
                    <MKBox>
                      <MKInput
                        type="text"
                        label="진단레포트 받을 이메일을 입력해 주세요*"
                        placeholder="진단레포트 받을 이메일을 입력해 주세요."
                        style={{ width: "100%" }}
                        value={userData.email}
                        onInput={isExistEmail}
                      />
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography variant="caption" color="error">
                        {errors.email && "이미 존재하는 이메일입니다."}
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={4} mb={1}></MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        variant="gradient"
                        color="dark"
                        style={{ color: "#03FF99" }}
                        fullWidth
                        onClick={signUp}
                        disabled={
                          Object.values(errors).filter((value) => value === true).length > 0
                        }
                      >
                        진단 준비 완료
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter />
      </MKBox>
    </>
  );
};

export default UserInfo;
