import { types } from "mobx-state-tree";

export const Options = types
  .model("Options", {
    text: types.string,
    read: types.string,
  })
  .views((self) => ({
    get show() {
      return { ...self };
    },
  }))
  .actions((self) => ({
    async setData(data) {
      Object.keys(data).forEach((d) => (self[d] = data[d]));
    },
    async reset() {
      self.text = "";
      self.read = "";
    },
  }));

export default Options;
