import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/test_bg.png";
import TopMenu from "pages/Componets/topMenu";
import { useMst } from "models";
import { MoonLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MKButton from "components/MKButton";

import complete from "assets/images/complete.png";
import ReportService from "services/ReportService";

const base = {
  9: 9,
  10: 11,
  11: 12,
  12: 13,
  13: 14,
  14: 15,
  15: 16,
  16: 17,
  17: 18,
  18: 19,
  19: 20,
  20: 21,
  21: 22,
  22: 23,
  23: 24,
  24: 25,
  25: 26,
  26: 27,
  27: 28,
  28: 29,
  29: 30,
  30: 31,
  31: 32,
  32: 33,
  33: 34,
  34: 38,
  35: 40,
  36: 44,
};

const strengthBase = {
  US: 35,
  VS: 33,
  S: 29,
  M: 22,
  L: 15,
  VL: 9,
};

function Loading() {
  const { user } = useMst();

  const naviagte = useNavigate();
  const location = useLocation();

  const [isComplete, setIsComplete] = useState(false);
  const [result, setResult] = useState({});

  const calculate = (sum, reportType) => {
    const normalized = {
      A: 0,
      D: 0,
      R: 0,
      E: 0,
    };

    const strength = {
      A: "",
      D: "",
      R: "",
      E: "",
    };

    normalized.A = base[sum.A];
    normalized.D = base[sum.D];
    normalized.R = base[sum.R];
    normalized.E = base[sum.E];

    let type = "";
    const deviationArray = [];
    Object.keys(normalized).forEach((n) => deviationArray.push(normalized[n]));

    Object.keys(sum).forEach((s) => {
      Object.keys(strengthBase).forEach((t) => {
        if (!strength[s] && sum[s] >= strengthBase[t]) {
          strength[s] = t;
          if (t === "US") {
            let original = true;
            Object.keys(sum).forEach((st) => {
              if (st !== s && sum[st] >= strengthBase["S"]) original = false;
            });

            if (original) type = s;
          }
        }
      });
    });

    let max = 0;
    let maxType = "";
    if (!type) {
      Object.keys(normalized).forEach((s) => {
        if (max < normalized[s]) {
          max = normalized[s];
          maxType = s;
        }
      });

      max = 0;
      Object.keys(normalized).forEach((s) => {
        if (s !== maxType && max < normalized[s]) {
          max = normalized[s];
          type = s;
        }
      });

      type = maxType + type;
    }

    const n = deviationArray.length;
    const mean = deviationArray.reduce((a, b) => a + b) / n;
    const deviation =
      Math.floor(
        Math.sqrt(deviationArray.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n) * 10
      ) / 10;

    return {
      title: "icruv2",
      type,
      sum,
      deviation,
      normalized,
      strength,
      reportType,
    };
  };

  useEffect(() => {
    const answer = location?.state?.answer || [];

    const sum = {
      A: 0,
      D: 0,
      R: 0,
      E: 0,
    };

    const sum2 = {
      A: 0,
      D: 0,
      R: 0,
      E: 0,
    };

    answer.map((item, index) => {
      if (item.answerType === "rank") {
        if (index % 2 === 0) {
          sum[item.answer[0].read] += 4;
          sum[item.answer[1].read] += 3;
          sum[item.answer[2].read] += 2;
          sum[item.answer[3].read] += 1;
        } else {
          sum2[item.answer[0].read] += 4;
          sum2[item.answer[1].read] += 3;
          sum2[item.answer[2].read] += 2;
          sum2[item.answer[3].read] += 1;
        }
      }
    });

    const icru = calculate(sum, "free");
    const icru2 = calculate(sum2, "stress");

    const diffR = sum2.R - sum.R;
    const diffE = sum2.E - sum.E;
    const diffA = sum2.A - sum.A;
    const diffD = sum2.D - sum.D;
    const diff = Math.max(diffR, diffE, diffA, diffD);
    let read;
    if (diffR === diff) read = "R";
    else if (diffE === diff) read = "E";
    else if (diffA === diff) read = "A";
    else if (diffD === diff) read = "D";

    icru.stressType = read;
    icru.stressSum = icru2.sum;

    setResult(icru);

    console.log("icru", icru, icru2);

    ReportService.setHistory(icru).then((res) => {
      if (res.code === 200) {
        setResult(res.data);
        setTimeout(() => {
          setIsComplete(true);
        }, 3000);
      }
    });
  }, []);

  return (
    <>
      <TopMenu />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          {!isComplete && (
            <>
              <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
                <MKTypography variant="h3" color="secondary" textAlign="center" mb={5}>
                  진단 중
                </MKTypography>
              </Grid>
              <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
                <MKTypography
                  variant="h6"
                  color="white"
                  textAlign="center"
                  mb={1}
                  style={{
                    fontFamily: "Pretendard",
                  }}
                >
                  {user.name}님의 자기다움을 읽고 있습니다......
                </MKTypography>
              </Grid>
              <Grid container item xs={8} lg={6} justifyContent="center" mx="auto" mt={5}>
                <MoonLoader color="#36d7b7" />
              </Grid>
            </>
          )}
          {isComplete && (
            <>
              <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
                <MKTypography variant="h3" color="secondary" textAlign="center" mb={5}>
                  진단 완료
                </MKTypography>
              </Grid>
              <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
                <MKTypography
                  variant="h6"
                  color="white"
                  textAlign="center"
                  mb={1}
                  style={{
                    fontFamily: "Pretendard",
                  }}
                >
                  ICRU기질진단이 완료되었습니다.
                  <br />
                  지금 바로 진단 결과를 확인하세요.
                </MKTypography>
              </Grid>
              <Grid container item xs={8} lg={6} justifyContent="center" mx="auto" mt={5}>
                <MKBox component="img" src={complete} alt="brand" width={150} />
              </Grid>
              <Grid container item xs={8} lg={6} justifyContent="center" mx="auto" mt={5}>
                <MKButton
                  variant="gradient"
                  color="success"
                  size="large"
                  sx={{ mb: 2 }}
                  style={{
                    background: "#03FF99",
                    borderRadius: "81.6801px",
                    fontFamily: "Pretendard",
                    fonttyle: "normal",
                    fontWeight: 800,
                    fontSize: "17.9696px",
                    color: "#004952",
                    width: "243px",
                  }}
                  onClick={() => {
                    naviagte("/report", { state: { result } });
                  }}
                >
                  진단 결과 확인
                </MKButton>
              </Grid>
            </>
          )}
        </Container>
      </MKBox>
    </>
  );
}

export default Loading;
