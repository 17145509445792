import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKProgress from "components/MKProgress";
import MKTypography from "components/MKTypography";
import ReactDragList from "react-drag-list";
import { Icon } from "@mui/material";
import "./style.css";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import TopMenu from "pages/Componets/topMenu";
import { useMst } from "models";
import ReportService from "services/ReportService";

const Presentation = () => {
  const navigate = useNavigate();

  const { user } = useMst();
  const [step, setStep] = useState(0);
  const [answerTemp, setAnswerTemp] = useState([]);

  useEffect(() => {
    setStep(0);

    const getQuestions = async () => {
      const history = await ReportService.getHistory();

      let reportCount = history.data.length;
      const questionResult = await ReportService.getQuestions();

      console.log(questionResult);
      if (questionResult.result && questionResult.code === 200) {
        // step.setQuestions(questionResult.data);

        let basic = [];
        let recovery = [];
        let temp = [];

        for (let i = 0; i < questionResult.data.length; i++) {
          if (questionResult.data[i].type === "basic") {
            basic.push({
              type: questionResult.data[i].type,
              title: questionResult.data[i].question,
              answerType: questionResult.data[i].answerType,
              userId: user.id,
              questionsId: i < 9 ? questionResult.data[i].id : 0,
              answer: questionResult.data[i].options,
              options:
                questionResult.data[i].answerType === "rank"
                  ? questionResult.data[i].options
                  : [questionResult.data[i].options[0]],
            });
          } else if (questionResult.data[i].type === "recovery") {
            recovery.push({
              type: questionResult.data[i].type,
              title: questionResult.data[i].question,
              answerType: questionResult.data[i].answerType,
              userId: user.id,
              questionsId: i < 9 ? questionResult.data[i].id : 0,
              answer: questionResult.data[i].options,
              options:
                questionResult.data[i].answerType === "rank"
                  ? questionResult.data[i].options
                  : [questionResult.data[i].options[0]],
            });
          }
        }
        if (reportCount === 0) {
          for (let i = 1; i <= 9; i++) {
            if (i === 9) {
              temp.push(basic[0]);
            } else {
              temp.push(basic[i]);
            }
            temp.push(recovery.splice(Math.floor(Math.random() * recovery.length), 1)[0]);
          }
        } else {
          for (let i = 1; i <= 9; i++) {
            if (i === 9) {
              temp.push(basic[0]);
            } else {
              let rand = Math.random() * basic.length;
              if (rand === 0) rand = 1;
              temp.push(basic.splice(Math.floor(rand), 1)[0]);
            }
            temp.push(recovery.splice(Math.floor(Math.random() * recovery.length), 1)[0]);
          }
        }

        setAnswerTemp(temp);
        console.log(answerTemp);
        console.log(temp);
        // step.setAnswerInit(answerTemp);
      } else {
        // toast.error("질문지 불러오기 실패!! 처움부터 다시 시도!")
        return;
      }
    };

    getQuestions();
  }, []);

  const onUpdated = (e, updated) => {
    console.log(updated);
    if (e.oldIndex > e.newIndex) {
      for (let i = e.newIndex; i <= e.oldIndex; i++) {
        e.to.children[i].children[0].children[2].innerHTML = i + 1 + ". ";
      }
    } else {
      for (let i = e.oldIndex; i <= e.newIndex; i++) {
        e.to.children[i].children[0].children[2].innerHTML = i + 1 + ". ";
      }
    }

    // const a = {
    //   type: question.type,
    //   answerType: question.answerType,
    //   title: question.title,
    //   userId: user.id,
    //   questionsId: question.id,
    //   answer: JSON.parse(JSON.stringify(updated)),
    //   options: JSON.parse(JSON.stringify(question.options.toJSON())),
    // }

    // step.setAnswer(question.order-1, a)
  };

  return (
    <>
      <TopMenu />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "#212024",
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={16}
            sm={16}
            md={16}
            lg={16}
            xl={8}
            xxl={8}
            justifyContent="center"
            mx="auto"
            mt={0}
          >
            {answerTemp.length > 0 && (
              <MKBox
                style={{
                  minHeight: "30vh",
                  width: "100%",
                  background: "#FFFFFF",
                  boxShadow: "0px 0px 50px rgba(1, 1, 1, 0.44)",
                  borderRadius: "10px",
                  padding: "30px 40px 40px 40px",
                }}
              >
                <MKProgress value={((step + 1) / 18) * 100} color="secondary" width="100%" />
                <MKTypography variant="h5" color="black" textAlign="center" mt={3}>
                  {step + 1 < 10 ? "0" + (step + 1) : step + 1}
                </MKTypography>
                <MKTypography variant="h5" color="black" textAlign="center" mt={3} mb={5}>
                  {answerTemp[step].title}
                </MKTypography>
                <ReactDragList
                  dataSource={answerTemp[step].options.sort(() => Math.random() - 0.5)} //렌더링할 데이터 레코드 배열
                  rowKey="read" //렌더링할 행 키
                  row={(record, index) => (
                    <MKBox
                      key={"row-" + index}
                      textAlign="center"
                      mt={2}
                      style={{
                        boxSizing: "border-box",
                        display: "flex",
                        flexSirection: "row",
                        alignItems: "center",
                        padding: "15px",
                        gap: "5px",
                        background: "#FBFBFB",
                        border: "1px solid #E9E9E9",
                        borderRadius: "8px",
                        flex: "none",
                        order: 0,
                        flexGrow: 0,
                      }}
                    >
                      {/* <Icon fontSize="medium" color="black">
                        format_line_spacing
                      </Icon> */}
                      <Icon fontSize="medium" style={{ color: "#9D9D9D" }}>
                        dehaze
                      </Icon>
                      <Icon className="move" fontSize="medium" color="black">
                        swap_vert
                      </Icon>
                      <MKTypography variant="h6" style={{ color: "#212024" }} textAlign="left">
                        {parseInt(index) + 1}.
                      </MKTypography>
                      <MKTypography variant="h6" style={{ color: "#212024" }} textAlign="left">
                        {record.text}
                      </MKTypography>
                    </MKBox>
                  )} //렌더링할 행 데이터
                  handles={false} //드래그 핸들 표시
                  className="simple-drag"
                  rowClassName="simple-drag-row"
                  onUpdate={onUpdated} //정렬 목록이 변경될 때 호출됨
                />
                <MKBox display="flex" justifyContent="space-between" mt={5}>
                  <MKButton
                    variant="gradient"
                    color="success"
                    size="medium"
                    component={Link}
                    to="/question"
                    sx={{ mb: 2 }}
                    disabled={step === 0}
                    style={{
                      opacity: step === 0 ? 0 : 1,
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px 20px",
                      background: "#FFFFFF",
                      border: "1px solid #D8D8D8",
                      borderRadius: "4px",
                      flex: "none",
                      order: 0,
                      flexGrow: 0,
                      color: "#5A5A5A",
                      width: "67px",
                    }}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    이전
                  </MKButton>
                  <MKButton
                    variant="gradient"
                    color="success"
                    size="medium"
                    answer={answerTemp}
                    sx={{ mb: 2 }}
                    style={{
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px 20px",
                      background: "#272727",
                      borderRadius: "4px",
                      flex: "none",
                      order: 0,
                      flexGrow: 0,
                      color: "#FFFFFF",
                      width: "65px",
                    }}
                    onClick={() => {
                      if (step === 17) {
                        navigate("/loading", { state: { answer: answerTemp } });
                        // alert("마지막 문항입니다.");
                      } else {
                        setStep(step + 1);
                      }
                    }}
                  >
                    {step === 17 ? "제출" : "다음"}
                  </MKButton>
                </MKBox>
              </MKBox>
            )}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
};

export default Presentation;
