/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { Provider, rootStore } from "models";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import IntroPage from "layouts/pages/intro";

// Material Kit 2 React routes
import routes from "routes";
import SignInPage from "layouts/pages/authentication/sign-in";
import SignUpPage from "layouts/pages/authentication/sign-up";
import QuestionPage from "layouts/pages/test/question";
import { observer } from "mobx-react";
import ReportPage from "layouts/pages/report";
import MypagePage from "layouts/pages/mypage";
import CallbackPage from "layouts/pages/authentication/sign-in/callback";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignOut from "pages/LandingPages/SignIn/signOut";
import PointPage from "pages/Mypage/point";
import LoadingPage from "pages/Report/loading";
import ResultPage from "layouts/pages/mypage/result";
import TrackingPage from "layouts/pages/mypage/tracking";
import ResultDetailPage from "layouts/pages/mypage/resultDetail";
import TeamPage from "layouts/pages/mypage/team";
import TeamDetail from "pages/Mypage/teamDetail";
import JoinusPage from "pages/LandingPages/SignUp/joinus";
import UserInfo from "pages/Test/userInfo";

const App = observer(() => {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.name} />;
      }

      return null;
    });

  return (
    <Provider value={rootStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route key="presentation" path="/presentation" element={<Presentation />} />
          <Route key="sign-in" path="/sign-in" element={<SignInPage />} />
          <Route key="sign-up" path="/sign-up" element={<SignUpPage />} />
          <Route key="sign-out" path="/sign-out" element={<SignOut />} />
          <Route key="intro" path="/intro" element={<IntroPage />} />
          <Route key="question" path="/question" element={<QuestionPage />} />
          <Route key="report" path="/report" element={<ReportPage />} />
          <Route key="mypage" path="/mypage" element={<MypagePage />} />
          <Route key="callback" path="/callback" element={<CallbackPage />} />
          <Route key="point" path="/point" element={<PointPage />} />
          <Route key="result" path="/result" element={<ResultPage />} />
          <Route key="result-detail" path="/result-detail" element={<ResultDetailPage />} />
          {/* <Route key="tracking" path="/tracking" element={<TrackingPage />} /> */}
          <Route key="loading" path="/loading" element={<LoadingPage />} />
          <Route key="team" path="/team" element={<TeamPage />} />
          <Route key="teamDetail" path="/tracking" element={<TeamDetail />} />
          <Route key="joinus" path="/joinus" element={<JoinusPage />} />
          <Route key="userInfo" path="/user-info" element={<UserInfo />} />
          <Route key="admin" path="/admin" element={<MypagePage />} />
          <Route key="i" path="*" element={<Navigate to="/test" />} />
        </Routes>
        <ToastContainer style={{ fontSize: 14 }} />
      </ThemeProvider>
    </Provider>
  );
});

export default App;
