import { APIClient } from "../utils/apiHelper";

const { get, post } = new APIClient();

export default {
  getQuestions: () => get(`/questions/new_icru`),
  chargePoint: (pointData) => post(`/point/charge`, pointData),
  getHistory: () => get(`/history`),
  setHistory: (historyData) => post(`/history`, historyData),
  payment: (paymentData) => post(`/history/payment`, paymentData),
  download: (userId, historyId) => get(`/download/` + userId + "/" + historyId),
  sendmail: (data) => post(`/send-email/`, data),
  teamCreate: (data) => post(`/team/`, data),
  teamUpdate: (data) => post(`/team/update`, data),
  teamList: (data) => post(`/team/get`, data),
  teamRemove: (data) => post(`/team/remove`, data),
  rankCreate: (data) => post(`/team/rank`, data),
  rankUpdate: (data) => post(`/team/rank/update`, data),
  rankList: (data) => post(`/team/rank/get`, data),
  rankRemove: (data) => post(`/team/rank/remove`, data),
  getTeams: (data) => post(`/team/users`, data),
  joinTeam: (data) => post(`/team/join`, data),
  getTeamHistory: (data) => post("/team/history", data),
  sendEmail2: (data) => post('/send/email/admin', data),
  getCount: (data) => post('/company/count', data)
};
