const industry = [
  "의료·보건",
  "마케팅·광고·홍보",
  "서비스",
  "특수계층·공공",
  "교육",
  "미디어·문화·스포츠",
  "IT·인터넷",
  "디자인",
  "연구개발·엔지니어",
  "경영·기획",
  "전문직",
  "무역·유통",
  "영업·판매·고객상담",
  "건설",
  "생산·제조",
  "기타",
];

const jobGroup = [
  [
    "간호사·간호조무사",
    "의료기사",
    "사무·원무·코디",
    "수의사·수의간호",
    "약사",
    "의사·한의사",
    "의료직 기타",
  ],
  [
    "마케팅·브랜드",
    "홍보·사보",
    "광고·광고기획",
    "리서치·조사·분석·통계",
    "이벤트·행사",
    "전시·컨벤션",
  ],
  [
    "레저·스포츠",
    "여행·호텔·항공·교통",
    "외식·식음료·요리",
    "경비·보안·경호",
    "건물·시설관리",
    "뷰티·미용·애완",
    "사회복지·상담·자원봉사",
  ],
  ["공무원·공사", "장교·군인·부사관"],
  [
    "입시·보습 학원강사",
    "외국어·IT·전문직업 강사",
    "학습지, 방문교사·과외",
    "유치원·어린이집",
    "교사·교수·강사",
    "교직원",
  ],
  [
    "연출·PD·감독",
    "시나리오·방송 작가",
    "방송진행·아나운서·리포터",
    "촬영·편집",
    "기자",
    "공연·전시·무대",
    "인쇄·출판·편집",
    "스포츠·운동선수",
    "문화·예술·창작",
    "음악·음향",
    "영화·배급",
    "크리에이터",
    "엔터테인먼트·연예·모델·매니저",
  ],
  [
    "웹기획·웹마케팅·IT PM",
    "콘텐츠·사이트·커뮤니티 운영",
    "웹디자인",
    "웹프로그래머",
    "응용프로그래머",
    "시스템프로그래머",
    "서버·보안·네트워크·DB",
    "시스템분석·설계·시스템엔지니어",
    "게임 기획·개발·운영",
    "HTML·코딩·퍼블리셔",
    "AI·빅데이터",
  ],
  [
    "시각·광고디자인",
    "출판·편집디자인",
    "산업·제품디자인",
    "패션·의류디자인",
    "캐릭터디자인",
    "CG그래픽디자인",
    "공간·전시디자인",
  ],
  [
    "기계·자동차·조선·철강",
    "디스플레이·반도체",
    "화학·에너지",
    "제약·식품",
    "전자·제어·전기",
    "기계설계·CAD·CAM",
    "정보통신·네트워크",
  ],
  [
    "경영·전략·기획",
    "총무·법무·사무",
    "경리·세무·회계",
    "재무·자금·IR",
    "비서·수행·안내",
    "인사·교육",
  ],
  [
    "특허·법무",
    "통역·번역",
    "금융·자산관리",
    "경영분석·컨설팅",
    "회계·세무사(공인)",
    "헤드헌터·노무·직업상담",
    "사회 / 인문 등의 연구직·공학개발직",
    "문화·사서",
    "감정·경매·변리",
  ],
  ["무역·해외영업", "무매·자재·재고", "유통·물류", "배송·운송", "상품기획"],
  [
    "일반영업",
    "금융·보험영업",
    "기술영업(IT / 솔루션)",
    "영업관리·영업지원",
    "판매·매장관리",
    "아웃바운드TM",
    "인바운드TM·CS·고객센터",
    "고객관리",
    "교육상담·학원관리",
    "부동산·창업",
  ],
  ["건축설계·인테리어", "토목·측량·조경·환경", "기계·전기·소방·설비", "시공·감리·공무"],
  [
    "생산관리·품질관리",
    "생산·제조·조립·설비",
    "포장·검사·가공",
    "설치·정비·AS",
    "농축산·어업·임엄",
  ],
  ["주부", "중 / 고등학생", "정년퇴직"],
];

export { industry, jobGroup };
