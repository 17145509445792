import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bg2Logo from "assets/images/report_bg2_logo.svg";
import bgr from "assets/images/report_r.svg";
import bgr2 from "assets/images/report_r2.png";
import bge from "assets/images/report_e.svg";
import bge2 from "assets/images/report_e2.png";
import bga from "assets/images/report_a.svg";
import bga2 from "assets/images/report_a2.png";
import bgd from "assets/images/report_d.svg";
import bgd2 from "assets/images/report_d2.png";
import bg3Sample from "assets/images/report_bg3.svg";
// import basicBtn from "assets/images/report_basic_btn.svg";
// import premiumBtn from "assets/images/report_premium_btn.svg";

import Footer from "examples/Footers/Footer";
import footerRoutes from "footer.routes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
import { useMst } from "models";
import { Icon } from "@mui/material";
import ReportService from "services/ReportService";
import { toast } from "react-toastify";
import { getItem } from "utils/localstorage";
import config from "../../config";

function Report() {
  const { user } = useMst();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("R");
  const [message, setMessage] = useState(false);

  const reportData = {
    R: {
      title: "창조가 라인이시군요!",
      bg: bgr,
      bg2: bgr2,
    },
    E: {
      title: "상담가 라인이시군요!",
      bg: bge,
      bg2: bge2,
    },
    A: {
      title: "행동가 라인이시군요!",
      bg: bga,
      bg2: bga2,
    },
    D: {
      title: "분석가 라인이시군요!",
      bg: bgd,
      bg2: bgd2,
    },
  };

  useEffect(() => {
    setType(location.state?.result?.type.substring(0, 1) || "R");
    // setType("E");
  }, []);

  const payment = async (point) => {
    if (!user.independent) {
      if (user.point < point) {
        setMessage(true);
        return;
      }

      const temp = { ...location.state?.result, payment: point };
      ReportService.payment(temp).then((res) => {
        if (res.code === 200) {
          navigate("/result-detail", { state: { selectedItem: temp } });
          // setSelectedItem(temp);
        }
      });
    } else {
      setLoading(true);
      const response = await fetch(
        config.API_URL +
          "/download/" +
          location.state?.result?.userId +
          "/" +
          location.state?.result?.id +
          "/" +
          point,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "client-id": "kr.co.icru",
            "user-id": user.id,
            authorization: "Bearer " + getItem("access-token"),
          },
        }
      );

      if (response) {
        const blob = await response.blob();
        const fileName =
          user.name +
          (point === "premium" ? " 기질진단 프리미엄 레포트.pdf" : " 회복탄력성 레포트.pdf");

        // 가상 링크 DOM 만들어서 다운로드 실행
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("파일다운로드를 성공하였습니다.");
      } else {
        toast.error("파일다운로드를 실패하였습니다.");
      }
      setLoading(false);
    }
  };

  return (
    <MKBox style={{ backgroundColor: "black" }}>
      <TopMenu loading={loading} />
      <MKBox
        width="100%"
        sx={{
          backgroundColor: "#010101",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
            <MKTypography variant="h4" color="secondary" textAlign="center" mt={20}>
              본성은 본질로 부터
            </MKTypography>
          </Grid>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={20} mb={10}>
            <MKBox>
              <MKTypography variant="h6" color="white" textAlign="center" mb={1}>
                칼 구스타프 융의 4가지 인지기능을 기반으로,4{""}
                <br />
                현존하는 79개의 심리유형 진단도구를{""}
                <br />
                최초로 통합한 기질 유형 진단도구 입니다.{""}
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        width="100%"
        sx={{
          backgroundColor: "#FBFBFB",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={0}>
            <MKTypography variant="h6" color="dark" textAlign="center" mt={2} mb={2}>
              ABOUT ICRU
            </MKTypography>
            <MKTypography
              variant="h6"
              textAlign="center"
              mt={2}
              ml={2}
              style={{
                color: "#A6A6A6",
              }}
            >
              ICRU는 I Can R.E.A.D yoU의 약자로, 칼 구스타프 융의 4가지 인지기능을 기반으로 현존하는
              79개의 심리유형 진단도구를 통합하여 연구개발 된 기질 진단 도구입니다.{""}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox
        width="100%"
        sx={{
          backgroundColor: "#ffffff",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={20}>
          <MKBox component="img" src={bg2Logo} />
        </Grid>
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={10}>
          <MKTypography variant="h3" color="dark" textAlign="center" mt={2} ml={2}>
            {user.name}님의 ICRU기질진단 결과.{""}
          </MKTypography>
        </Grid>
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={20}>
          <MKBox
            style={{
              backgroundColor: "#FBFBFB",
              width: "120%",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <MKTypography variant="h6" color="dark" textAlign="left">
              먼저 사람에 대한 이해를 높여줄{""}
            </MKTypography>
            <MKTypography variant="h5" color="dark" textAlign="left">
              4가지 대표기질 알아보기.{""}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={5}>
          <MKBox
            style={{
              width: "120%",
              borderLeft: "5px solid #B931F0",
              paddingLeft: "20px",
            }}
          >
            <MKTypography variant="h5" color="dark" textAlign="left" style={{ color: "#B931F0" }}>
              나는 어떤 라인?{""}
            </MKTypography>
            <MKTypography variant="h3" color="dark" textAlign="left">
              {reportData[type].title}
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={2}>
          <MKBox component="img" src={reportData[type].bg} width="120%" />
        </Grid>
        <Grid
          container
          item
          xs={8}
          lg={8}
          display={"flex"}
          justifyContent="center"
          alignContent={"center"}
          mx="auto"
          mt={2}
        >
          <MKBox py={2} px={2} component="img" src={reportData[type].bg2} width="120%" />
        </Grid>
          {/* <MKBox
            mt={3}
            py={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              cursor: "pointer",
              border: "1px solid #eeeeee",
              borderRadius: "4px",
              background: "#000000",
            }}
            onClick={() => {
              payment("premium");
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              ml={1}
              style={{
                color: "white",
              }}
            >
              ICRU 홈페이지로 가기
            </MKTypography>
          </MKBox> */}
        {/* <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={2}>
            <Link to="/question" style={{ marginTop: 30 }}>
              <MKBox component="img" src={basicBtn} />
            </Link>
            <Link to="/question">
              <MKBox component="img" src={premiumBtn} />
            </Link>
          </Grid> */}
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={2}>
          <MKBox component="img" src={bg3Sample} />
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={0} style={{ backgroundColor: "#03FF99", justifyContent: "center" }}>
        <Footer content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default Report;
