import { useMst } from "../../../models";
import { useEffect } from "react";

function SignOut() {
  const { user } = useMst();

  useEffect(() => {
    user.logout();
    localStorage.clear();
    window.location.href = "/admin";
  }, []);
}

export default SignOut;
