/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import brandImage from "assets/images/brand_dark.png";

// import pIcon from "assets/images/p.png";
// import cIcon from "assets/images/c.png";
// import sIcon from "assets/images/s.png";
// import gIcon from "assets/images/g.png";

import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import { AppBar, Container, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
import AuthService from "services/AuthService";
import { getItem } from "utils/localstorage";

const SignUpBasic = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [history, setHistory] = useState(null);
  const [snsType, setSnsType] = useState(null);
  const [type, setType] = useState(null);
  const [userData, setUserData] = useState({
    userId: "",
    password: "",
    passwordConfirm: "",
    name: "",
    birthYear: "",
    gender: -1,
    phone: "",
    phoneCode: "",
    email: "",
    emailCode: "",
  });

  const [errors, setErrors] = useState({
    userId: false,
    password: false,
    passwordConfirm: false,
    name: false,
    birthYear: false,
    gender: -1,
    phone: false,
    phoneCode: false,
    email: false,
    emailCode: false,
  });

  useEffect(() => {
    const accessToken = getItem("access-token");
    if (accessToken) {
      navigate("/intro");
    }

    if (location.state?.kakao) {
      setSnsType("kakao");
      const kakao = location.state.kakao;
      console.log("kakao", kakao);
      setUserData({
        ...userData,
        userId: kakao.id,
        name: kakao.kakao_account.profile.nickname,
        email: kakao.kakao_account.email,
      });
      setType("user");
    }
  }, []);

  const handleTabType = (event, newValue) => setUserData({ ...userData, gender: newValue });

  const signUp = async () => {
    console.log(userData);
    if (!userData.userId) {
      toast.error("아이디를 입력해주세요.");
      return;
    }
    if (!userData.password && !snsType) {
      toast.error("비밀번호를 입력해주세요.");
      return;
    }
    if (!userData.passwordConfirm && !snsType) {
      toast.error("비밀번호 확인을 입력해주세요.");
      return;
    }
    if (userData.password !== userData.passwordConfirm) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return;
    }
    if (!userData.name) {
      toast.error("이름을 입력해주세요.");
      return;
    }
    if (type === "user" && !userData.birthYear) {
      toast.error("출생년도를 입력해주세요.");
      return;
    }
    if (type === "user" && userData.gender < 0) {
      toast.error("성별을 선택해주세요.");
      return;
    }
    if (!userData.phone) {
      toast.error("전화번호를 입력해주세요.");
      return;
    }
    if (!userData.email) {
      toast.error("이메일을 입력해주세요.");
      return;
    } else {
      // const isExistEmail = await AuthService.userEmail(userData.email);

      // if (isExistEmail.code === 200) {
      //   console.log(isExistEmail);
      //   setHistory(isExistEmail.data);
      // } else {
      userData.type = type;
      if (snsType === "kakao") {
        userData.userId = userData.userId.toString();
        userData.sns = { kakao: location.state.kakao };
      }

      AuthService.signUp(userData).then((res) => {
        console.log(res);
        if (res?.code === 200) {
          if (res.data.result) {
            toast.success("회원가입이 완료되었습니다.");
            if (type === "user" && res.data.users.length > 0) {
              setHistory(res.data.users);
            } else {
              navigate("/sign-in");
            }
          } else {
            toast.error("이미 회원가입이 되어 있습니다.");
          }
        } else {
          toast.error("회원가입에 실패하였습니다.");
        }
      });
      // }
    }
    // if (!userData.emailCode) {
    //   toast.error("이메일 인증번호를 입력해주세요.");
    //   return;
    // }
  };

  const typeText = {
    user: {
      title: "개인 회원가입",
      icon: "fas fa-user",
      userId: "아이디",
      name: "이름",
    },
    company: {
      title: "기업 회원가입",
      icon: "fas fa-building",
      userId: "관리자 아이디",
      name: "회사명",
      adminName: "관리자 이름",
    },
    school: {
      title: "학교 회원가입",
      icon: "fas fa-school",
      userId: "관리자 아이디",
      name: "학교명",
      adminName: "관리자 이름",
    },
    franchisee: {
      title: "가맹점 회원가입",
      icon: "fas fa-handshake",
      userId: "가맹점 아이디",
      name: "가맹점명",
      adminName: "관리자 이름",
    },
  };

  const isExistUserId = (e) => {
    if (e.target.value.length > 2) {
      AuthService.isExistUserId(e.target.value).then((res) => {
        console.log(res);
        if (res?.code === 200) {
          setErrors({ ...errors, userId: res.data });
        }
      });
    }
    setUserData({ ...userData, userId: e.target.value });
  };

  const isExistEmail = (e) => {
    // console.log(e.target.value);
    // if (e.target.value.length > 2) {
    //   AuthService.isExistEmail(e.target.value).then((res) => {
    //     console.log(res);
    //     if (res.code === 200) {
    //       setErrors({ ...errors, email: res.data });
    //     }
    //   });
    // }
    setUserData({ ...userData, email: e.target.value });
  };

  const recovery = () => {
    AuthService.recovery(userData.email).then((res) => {
      console.log(res);
      if (res.code === 200) {
        toast.success("복구가 완료되었습니다.");
        navigate("/sign-in");
      } else {
        toast.error("복구에 실패하였습니다.");
      }
    });
  };
  return (
    <>
      <DefaultNavbar
        brandImage={brandImage}
        routes={routes}
        action={[
          {
            type: "internal",
            route: "/sign-in",
            label: "로그인",
            color: "dark",
          },
          {
            type: "internal",
            route: "/sign-up",
            label: "회원가입",
            color: "dark",
          },
        ]}
        transparent={false}
        center
      />
      <MKBox position="absolute" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          {!type && (
            <Grid item xs={8} lg={8}>
              <Card>
                <MKBox p={7}>
                  <MKBox mx={2} p={2} mb={1} textAlign="center">
                    <MKTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                      회원가입
                    </MKTypography>
                  </MKBox>
                  <Container>
                    <Grid
                      container
                      item
                      xs={8}
                      lg={8}
                      sx={{ mx: "auto" }}
                      justifyContent="space-between"
                    >
                      <MKBox
                        justifyContent="center"
                        component={Link}
                        display="flex"
                        mt={1}
                        my={1}
                        p={2}
                        style={{
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          width: "155px",
                          height: "50px",
                          borderRadius: "4px",
                          gap: "10px",
                        }}
                        onClick={() => setType("user")}
                      >
                        <Icon>account_circle_outlined_icon</Icon>
                        <MKTypography component="span" variant="button" fontWeight="regular">
                          개인
                        </MKTypography>
                      </MKBox>
                      <MKBox
                        justifyContent="center"
                        component={Link}
                        display="flex"
                        my={1}
                        p={2}
                        style={{
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          width: "155px",
                          height: "50px",
                          borderRadius: "4px",
                          gap: "10px",
                        }}
                        onClick={() => setType("company")}
                      >
                        <Icon>corporate_fare_outlined_icon</Icon>
                        <MKTypography component="span" variant="button" fontWeight="regular">
                          기업
                        </MKTypography>
                      </MKBox>
                      <MKBox
                        justifyContent="center"
                        component={Link}
                        display="flex"
                        mt={1}
                        my={1}
                        p={2}
                        style={{
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          width: "155px",
                          height: "50px",
                          borderRadius: "4px",
                          gap: "10px",
                        }}
                        onClick={() => setType("school")}
                      >
                        <Icon>school_outlined_icon</Icon>
                        <MKTypography component="span" variant="button" fontWeight="regular">
                          학교
                        </MKTypography>
                      </MKBox>
                      <MKBox
                        justifyContent="center"
                        component={Link}
                        display="flex"
                        mt={1}
                        my={1}
                        p={2}
                        style={{
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          width: "155px",
                          height: "50px",
                          borderRadius: "4px",
                          gap: "10px",
                        }}
                        onClick={() => setType("franchisee")}
                      >
                        <Icon>handshake</Icon>
                        <MKTypography component="span" variant="button" fontWeight="regular">
                          가맹점
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <MKBox mt={5} textAlign="center" display="flex" justifyContent="center">
                      <MKTypography component="span" variant="button" fontWeight="regular">
                        계정이 있으신가요?
                      </MKTypography>
                      <MKTypography
                        ml={1}
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        로그인하기
                      </MKTypography>
                      <MKTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        <Icon fontSize="small">keyboard_arrow_right</Icon>
                      </MKTypography>
                    </MKBox>
                  </Container>
                </MKBox>
              </Card>
            </Grid>
          )}
          {type && !history && (
            <Grid item xs={11} sm={4} md={4} lg={4} xl={4}>
              <Card>
                <MKBox mx={2} p={2} mb={1} textAlign="center">
                  <MKTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                    {typeText[type].title}
                  </MKTypography>
                </MKBox>
                <MKBox pt={4} pb={3} px={3}>
                  <MKBox component="form" role="form">
                    {!snsType && (
                      <>
                        <MKBox>
                          <MKInput
                            type="text"
                            label={(typeText[type] && typeText[type].userId) || "아이디*"}
                            placeholder={(typeText[type] && typeText[type].userId) || "아이디*"}
                            fullWidth
                            value={userData.userId}
                            onInput={isExistUserId}
                          />
                        </MKBox>
                        <MKBox mb={2}>
                          <MKTypography variant="caption" color="error">
                            {errors.userId && "이미 존재하는 아이디입니다."}
                          </MKTypography>
                        </MKBox>
                        <MKBox mb={2}>
                          <MKInput
                            type="password"
                            label="비밀번호*"
                            placeholder="영문+숫자 조합 8자리 이상"
                            fullWidth
                            value={userData.password}
                            onInput={(e) => setUserData({ ...userData, password: e.target.value })}
                          />
                        </MKBox>
                        <MKBox mb={2}>
                          <MKInput
                            type="password"
                            label="비밀번호 확인*"
                            placeholder="비밀번호를 한번 더 입력해 주세요."
                            fullWidth
                            value={userData.passwordConfirm}
                            onInput={(e) =>
                              setUserData({ ...userData, passwordConfirm: e.target.value })
                            }
                          />
                        </MKBox>
                      </>
                    )}
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label={(typeText[type] && typeText[type].name) || "이름*"}
                        placeholder={(typeText[type] && typeText[type].name) || "이름*"}
                        fullWidth
                        value={userData.name}
                        onInput={(e) => setUserData({ ...userData, name: e.target.value })}
                      />
                    </MKBox>
                    {type === "user" && (
                      <MKBox mb={2}>
                        <MKInput
                          type="number"
                          label="출생년도*"
                          placeholder="출생연도를 입력해 주세요.(ex 1992)"
                          fullWidth
                          value={userData.birthYear}
                          onInput={(e) => setUserData({ ...userData, birthYear: e.target.value })}
                        />
                      </MKBox>
                    )}
                    {type === "user" && (
                      <MKBox mb={2}>
                        <AppBar position="static">
                          <Tabs value={userData.gender} onChange={handleTabType}>
                            <Tab
                              icon={
                                <MKBox
                                  component="i"
                                  color="dark"
                                  mr={1.25}
                                  sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                                  className="fas fa-male"
                                />
                              }
                              label="남"
                            />
                            <Tab
                              icon={
                                <MKBox
                                  component="i"
                                  color="dark"
                                  mr={1.25}
                                  sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                                  className="fas fa-female"
                                />
                              }
                              label="여"
                            />
                          </Tabs>
                        </AppBar>
                      </MKBox>
                    )}
                    {type !== "user" && (
                      <MKBox mb={2}>
                        <MKInput
                          label={(typeText[type] && typeText[type].adminName) || "관리자 이름*"}
                          placeholder={
                            (typeText[type] && typeText[type].adminName) || "관리자 이름*"
                          }
                          fullWidth
                          value={userData.adminName}
                          onInput={(e) => setUserData({ ...userData, adminName: e.target.value })}
                        />
                      </MKBox>
                    )}
                    <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label="전화번호*"
                        placeholder="전화번호를 입력해 주세요.(- 없이)"
                        style={{ width: "100%" }}
                        value={userData.phone}
                        onInput={(e) =>
                          setUserData({
                            ...userData,
                            phone: e.target.value.replace(/[^0-9]/gi, ""),
                          })
                        }
                      />
                      {/* <MKButton
                        variant="gradient"
                        style={{
                          marginLeft: 8,
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          borderRadius: 4,
                          height: 45,
                        }}
                      >
                        인증번호 전송
                      </MKButton> */}
                    </MKBox>
                    {/* <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label="인증번호*"
                        placeholder="인증번호를 입력해 주세요."
                        style={{ width: "75%" }}
                        value={userData.phoneCode}
                        onInput={(e) => setUserData({ ...userData, phoneCode: e.target.value })}
                      />
                      <MKButton
                        variant="gradient"
                        style={{
                          marginLeft: 8,
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          borderRadius: 4,
                          height: 45,
                        }}
                      >
                        인증확인
                      </MKButton>
                    </MKBox> */}
                    <MKBox>
                      <MKInput
                        type="text"
                        label="이메일*"
                        placeholder="이메일을 입력해 주세요."
                        style={{ width: "100%" }}
                        value={userData.email}
                        onInput={isExistEmail}
                      />
                      {/* <MKButton
                        variant="gradient"
                        style={{
                          marginLeft: 8,
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          borderRadius: 4,
                          height: 45,
                        }}
                      >
                        인증번호 전송
                      </MKButton> */}
                    </MKBox>
                    <MKBox mb={2}>
                      <MKTypography variant="caption" color="error">
                        {errors.email && "이미 존재하는 이메일입니다."}
                      </MKTypography>
                    </MKBox>
                    {/* <MKBox mb={2}>
                      <MKInput
                        type="text"
                        label="인증번호*"
                        placeholder="인증번호를 입력해 주세요."
                        style={{ width: "75%" }}
                        value={userData.emailCode}
                        onInput={(e) => setUserData({ ...userData, emailCode: e.target.value })}
                      />
                      <MKButton
                        variant="gradient"
                        style={{
                          marginLeft: 8,
                          color: "#287361",
                          backgroundColor: "#CDFFEA",
                          borderRadius: 4,
                          height: 45,
                        }}
                      >
                        인증확인
                      </MKButton>
                    </MKBox> */}
                    <MKBox mt={4} mb={1}></MKBox>
                    <MKBox mt={4} mb={1}>
                      <MKButton
                        variant="gradient"
                        color="dark"
                        style={{ color: "#03FF99" }}
                        fullWidth
                        onClick={signUp}
                        disabled={
                          Object.values(errors).filter((value) => value === true).length > 0
                        }
                      >
                        회원가입 완료
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          )}
          {history && (
            <Grid item xs={11} sm={4} md={4} lg={4} xl={4}>
              <Card>
                <MKBox p={7}>
                  <MKBox mx={2} p={2} mb={1} textAlign="center">
                    <MKTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                      기존 진단내역이 있습니다.
                    </MKTypography>
                  </MKBox>
                  <Container>
                    <Grid
                      container
                      item
                      xs={6}
                      lg={6}
                      sx={{ mx: "auto" }}
                      justifyContent="space-between"
                    >
                      {history.map(
                        (item, index) =>
                          item.history.length > 0 && (
                            <MKBox key={index} justifyContent="center" display="flex" p={2}>
                              <MKTypography component="span" variant="button" fontWeight="regular">
                                {item.name}({item.birthYear}년) {item.history.length}건
                              </MKTypography>
                            </MKBox>
                          )
                      )}
                    </Grid>
                    <MKBox mt={5} textAlign="center" display="flex" justifyContent="center">
                      <MKTypography component="span" variant="button" fontWeight="regular">
                        기존 진단내역 복구 하시겠습니까?
                      </MKTypography>
                      <MKTypography
                        ml={1}
                        component={Link}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                        onClick={() => recovery()}
                      >
                        복구
                      </MKTypography>
                    </MKBox>
                    <MKBox mt={5} textAlign="center" display="flex" justifyContent="center">
                      <MKTypography
                        ml={1}
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        로그인으로
                      </MKTypography>
                    </MKBox>
                  </Container>
                </MKBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter />
      </MKBox>
    </>
  );
};

export default SignUpBasic;
