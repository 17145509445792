import Container from "@mui/material/Container";

import MKBox from "components/MKBox";
import Sidemenu from "../Componets/sidemenu";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
import { useLocation, useResolvedPath } from "react-router-dom";
import ReportService from "services/ReportService";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
// import moment from "moment";
import { useMst } from "models";
import MKButton from "components/MKButton";
import { toast } from "react-toastify";
import { getItem } from "utils/localstorage";
import config from "../../config";
import moment from "moment";
import ReactSelect from 'react-select';
import MKInput from "components/MKInput";

function TeamDetail() {
  const location = useLocation();
  // const naviagte = useNavigate();
  // const [teams, setTeams] = useState([]);
  const [history, setHistory] = useState([]);
  const { user } = useMst();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const columns = [
    { field: "teamName", headerName: "팀/프로젝트명", width: 120 },
    { field: "name", headerName: "이름", width: 120 },
    { field: "gender", headerName: "성별", width: 110,
    renderCell: (params) => (
       params.value === 'M' ? '남' : '여'
    ),
   },
    { field: "rank", headerName: "직책", width: 110 },
    { field: "email", headerName: "이메일", width: 220 },
    // { field: "isAdmin", headerName: "관리자 권한", width: 100 },
    // { field: "isTest", headerName: "진단상태", width: 70 },
    {
      field: "createdAt",
      headerName: "진단일",
      width: 120,
      renderCell: (params) => (
        moment(params.value).format("YYYY-MM-DD")
      ),
    },
    // {
    //   field: "free",
    //   headerName: "무료",
    //   width: 80,
    //   renderCell: (params) => (
    //     <MKButton
    //       color="dark"
    //       onClick={() => naviagte("/report", { state: { result: { type: params.value } } })}
    //       style={{ mx: 20 }}
    //     >
    //       {params.value}
    //     </MKButton>
    //   ),
    // },
    {
      field: "type",
      headerName: "다운로드",
      width: 120,
      renderCell: (params) => (
        (
          <MKButton color="error" onClick={() => download("premium", params.row)}>
            {params.value}
          </MKButton>
        )
      ),
    },
    {
      field: "id",
      headerName: "",
      width: 120,
      renderCell: (params) => (
        <MKButton color="dark" onClick={() => sendEmail(params.row)}>
          {'이메일보내기'}
        </MKButton>
      ),
    },
  ];

  const sendEmail = (params) => {
    setLoading(true);
    ReportService.sendEmail2({
      userId: params.userId,
      historyId: params.id,
      reportType: 'premium'
    }).then(e => {
      toast.success(params.name + '님에게 이메일 발송 하였습니다.')
      setLoading(false);
    })
  }

  const download = async (type, row) => {
    if (type) {
      setLoading(true);
      const response = await fetch(
        config.API_URL + "/download/" + row.userId + "/" + row.id + "/" + type,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "client-id": "kr.co.icru",
            "user-id": user.id,
            authorization: "Bearer " + getItem("access-token"),
          },
        }
      );

      if (response) {
        const blob = await response.blob();
        const fileName =
        row.name +
          (type === "premium" ? " 기질진단 프리미엄 레포트.pdf" : " 회복탄력성 레포트.pdf");

        // 가상 링크 DOM 만들어서 다운로드 실행
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        toast.success("파일다운로드를 성공하였습니다.");
      } else {
        toast.error("파일다운로드를 실패하였습니다.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("location", location.state);

    ReportService.teamList({ companyId: user.me.id }).then((res) => {
      console.log("res", res);
      let team = []
      res.data.map(t =>
        team.push({
          label: t.teamName + ' (' + t.users.length + ')',
          value: t.id,
          code: t.code
        })
      )
      setTeams(team)
      if (team.length > 0) getHistory(location.state?.team ? location.state?.team?.code : team[0].code)
    });
  }, []);

  useEffect(() => {
    if (teams.length < 0) return
    // getHistory(location.state?.code || teams[0].code);
  }, [teams])


  const getHistory = (code) => {
    ReportService.getTeamHistory({ code }).then((res) => {
      console.log("res", res.data);
      setHistory(res.data);
    });
  };

  const onChange = (e) => {
    getHistory(e.code)
    console.log(e)
  }

  useEffect(() => {
    setFilterModel({
      items: [],
      quickFilterValues: [search],
    })
  }, [search])

  return (
    <>
      <TopMenu color="dark" loading={loading} />
      <MKBox
        width="100%"
        sx={{
          pt: 15,
          my: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            // flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", my: 0, mx: "auto", px: 0.75 }}
          >
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <Sidemenu />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mx: "auto" }} mb={20}>
              <MKBox
              display="flex"
              justifyContent="space-between"
              flex
              sx={{
                
              }}
              >
                <MKTypography variant="h5">팀 진단현황</MKTypography>
                <MKBox
                  display="flex"
                  style={{
                    fontSize: '10pt'
                  }}
                >
                  { teams.length > 0 &&
                  <ReactSelect
                      defaultValue={
                        location.state?.team ? {
                          label: location.state?.team?.teamName + ' (' 
                            + location.state?.team?.users?.length + ')',
                          value: location.state?.team?.id,
                          code: location.state?.team?.code,
                        } : teams[0]}
                      // isDisabled={isDisabled}
                      // isLoading={isLoading}
                      // isClearable={isClearable}
                      // isRtl={isRtl}
                      onChange={onChange}
                      isSearchable={true}
                      name='color'
                      options={teams}
                  />}
                  <MKInput
                    sx={{
                      marginLeft: 2
                    }}
                    type="text"
                    label="검색"
                    placeholder=""
                    value={search}
                    onInput={(e) => setSearch(e.target.value)}
                  />
                </MKBox>
              </MKBox>
              <DataGrid
                filterModel={filterModel}
                style={{ marginTop: "20px", fontSize: "13px" }}
                rows={history}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                // checkboxSelection
              />
            </Grid>
            {/* <Grid item xs={12} lg={10} sx={{ mx: "auto" }} mb={20}>
              <MKTypography variant="h5">팀 생성</MKTypography>
            </Grid> */}
          </Grid>
        </Container>
        {/* <Footer content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default TeamDetail;
