import Container from "@mui/material/Container";

import MKBox from "components/MKBox";

import Sidemenu from "../Componets/sidemenu";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { useMst } from "models";
import MKButton from "components/MKButton";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
import AuthService from "services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { getItem } from "utils/localstorage";
// import Footer from "examples/Footers/Footer";
// import footerRoutes from "footer.routes";

function Presentation() {
  const navigate = useNavigate()
  const { user } = useMst();

  const [isModifyMode, setIsModifyMode] = useState(false);
  const [name, setName] = useState(user.name);
  const [birthYear, setBirthYear] = useState(user.birthYear);
  const [phone, setPhone] = useState(user.phone);
  const [email, setEmail] = useState(user.email);

  useEffect(() => {
    if (!getItem("access-token")) navigate('/sign-in')
    else navigate('/team')

  }, [])

  return (
    <>
      <TopMenu color="dark" />
    </>
  );
}

export default Presentation;
