// import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Icon } from "@mui/material";
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
import { useMst } from "models";
import ReactModal from "react-modal";
import { useEffect, useState } from "react";
import MKInput from "components/MKInput";
import MKBadge from "components/MKBadge";
import { toast } from "react-toastify";
import ReportService from "services/ReportService";
import qs from "qs";
import { observer } from "mobx-react";

const customModalStyles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "400px",
    height: "300px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

function Sidemenu() {
  const { user } = useMst();
  const [modalOpen, setModalOpen] = useState(false);
  const [point, setPoint] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [selectedMenu] = useState(location.pathname);

  useEffect(() => {
    ReportService.getCount().then(res => {
        setPoint(res.data?.count || 0)
        setEmailCount(res.data?.emailCount || 0)
    })
  }, [])

  const onMouseEnterHandler = (e) => {
    if (e.target.innerText.indexOf("\n") < 0) return;
    e.target.style.background = "#E6FAF5";
  };

  const onMouseLeaveHandler = (e) => {
    if (e.target.href === location.href || e.target.innerText.indexOf("\n") < 0) return;
    e.target.style.background = "#ffffff";
  };

  const handlePopupMessage = () => {
    setModalOpen(!modalOpen);
  };

  const chargePoint = () => {
    if (point === 0 || point === "") {
      toast.error("충전할 포인트를 입력해주세요.");
      return;
    }

    ReportService.chargePoint({
      cmd: "payrequest",
      userid: "JNG0605",
      goodname: point.toLocaleString() + "원 충전",
      price: point,
      recvphone: user.phone || "01093286090",
      smsuse: "n",
      feedbackurl: "https://icru.co.kr/api/v2/point/result",
      var1: user.id,
      var2: user.type,
    }).then((res) => {
      if (res.code === 200) {
        const payapp = qs.parse(res.data);
        console.log("payapp", payapp);
        let new_window = window.open(
          payapp.payurl,
          "payapp",
          "width=800,height=800,top=20,left=300,toolbar=no,menubar=no,scrollbars=no,resizable=no"
        );
        window.open("some url");
        let timer = setInterval(function () {
          if (new_window.closed) {
            clearInterval(timer);
            location.reload();
          }
        }, 1000);
        setModalOpen(false);
      } else {
        toast.error("포인트 충전에 실패하였습니다.");
      }
    });
  };

  return (
    // <Grid container spacing={3} sx={{ mb: 50, width: "500px" }}>
    <MKBox
      position="sticky"
      // top="100px"
      // pb={{ xs: 2, lg: 6 }}
      style={
        {
          // width: "225px",
          // padding: "30px 25px",
          // gap: "20px",
          // background: "#FFFFFF",
        }
      }
    >
      <MKBox component={Link} display="flex" mb={2}>
        <MKTypography variant="h5" mb={1} ml={0}>
          관리페이지
        </MKTypography>
      </MKBox>
      {/* <MKBox
        component={Link}
        display="flex"
        to="/mypage"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        py={1}
        px={2}
        sx={{
          background: location.href.indexOf("mypage") > -1 ? "#E6FAF5" : "#ffffff",
        }}
      >
        <Icon>account_circle</Icon>
        <MKTypography variant="h6" ml={1}>
          {user.type === "user" ? "내정보" : "기본 정보"}
        </MKTypography>
      </MKBox> */}
      {user.type === "user" && (
        <MKBox
          component={Link}
          display="flex"
          to="/result"
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          py={1}
          px={2}
          sx={{
            background: location.href.indexOf("result") > -1 ? "#E6FAF5" : "#ffffff",
          }}
        >
          <Icon>assignment</Icon>
          <MKTypography variant="h6" ml={1}>
            진단결과
          </MKTypography>
        </MKBox>
      )}
      {user.type !== "user" && (
        <MKBox
          component={Link}
          display="flex"
          to="/team"
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          py={1}
          px={2}
          sx={{
            background: location.href.indexOf("team") > -1 ? "#E6FAF5" : "#ffffff",
          }}
        >
          <Icon>assignment</Icon>
          <MKTypography variant="h6" ml={1}>
            팀원관리
          </MKTypography>
        </MKBox>
      )}
      {(selectedMenu === "/team" || selectedMenu === "/team-detail") && (
        <>
          {/* <MKBox
            component={Link}
            display="flex"
            to="/team"
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            py={1}
            px={5}
          >
            <MKTypography
              variant="h6"
              ml={1}
              style={{
                fontSize: 14,
                color: location.href.indexOf("team") > -1 ? "#397E87" : "#000",
              }}
            >
              팀 생성 및 관리
            </MKTypography>
          </MKBox> */}
          {/* <MKBox
            component={Link}
            display="flex"
            to="/team-detail"
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            py={1}
            px={5}
          >
            <MKTypography
              variant="h6"
              ml={1}
              style={{
                fontSize: 14,
                color: location.href.indexOf("team-detail") > -1 ? "#397E87" : "#000",
              }}
            >
              진단 현황
            </MKTypography>
          </MKBox> */}
        </>
      )}
      <MKBox
        component={Link}
        display="flex"
        to="/tracking"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        py={1}
        px={2}
        sx={{
          background: location.href.indexOf("tracking") > -1 ? "#E6FAF5" : "#ffffff",
        }}
      >
        <Icon>assignment</Icon>
        <MKTypography variant="h6" ml={1}>
          진단현황
        </MKTypography>
      </MKBox>
      {/* <MKBox
        component={Link}
        display="flex"
        to="/point"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        py={1}
        px={2}
        sx={{
          background: location.href.indexOf("point") > -1 ? "#E6FAF5" : "#ffffff",
        }}
      >
        <Icon>local_parking</Icon>
        <MKTypography variant="h6" ml={1}>
          포인트 현황
        </MKTypography>
      </MKBox> */}
      {/* <MKBox
        component={Link}
        display="flex"
        to="/password"
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        py={1}
        px={2}
        sx={{
          background: location.href.indexOf("password") > -1 ? "#E6FAF5" : "#ffffff",
        }}
      >
        <Icon>lock</Icon>
        <MKTypography variant="h6" ml={1}>
          비밀번호 변경
        </MKTypography>
      </MKBox> */}
      <MKBox
        mt={1}
        display="row"
        style={{
          width: "175px",
          height: "125px",
          borderRadius: "4px",
          border: "1px solid #D8D8D8",
          padding: "10px",
          alignItems: "center"
        }}
      >
        <MKBox 
          mb={1}
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <MKTypography variant="h5">
            현재 진단 인원
          </MKTypography>
        </MKBox>
        <MKBox display="row" 
          style={{
            padding: "10px",
            alignItems: "center"
          }}>
          <MKTypography variant="h6" style={{ paddingLeft: 25, margin: "auto" }}>
           진단완료 : {point.toLocaleString('kr-ko')}명
          </MKTypography>
          <MKTypography variant="h6" style={{ paddingLeft: 25, margin: "auto" }}>
           메일발송 : {emailCount.toLocaleString('kr-ko')}명
          </MKTypography>
          {/* <MKButton color="dark" onClick={handlePopupMessage}>
            충전
          </MKButton> */}
        </MKBox>
      </MKBox>
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
        ariaHideApp={false}
        contentLabel="Pop up Message"
        shouldCloseOnOverlayClick={true}
      >
        <MKBox
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <MKTypography variant="h5">포인트 충전</MKTypography>
          <Icon onClick={() => setModalOpen(false)} style={{ cursor: "pointer" }}>
            close
          </Icon>
        </MKBox>
        <MKBox justifyContent="right" display="flex" mt={2}>
          <MKBadge
            color="info"
            badgeContent={"+50,000원"}
            style={{ cursor: "pointer" }}
            onClick={() => setPoint((p) => parseInt(p || 0) + 50000)}
          />
          <MKBadge
            color="info"
            badgeContent={"+10,000원"}
            style={{ cursor: "pointer" }}
            onClick={() => setPoint((p) => parseInt(p || 0) + 10000)}
          />
          <MKBadge
            color="info"
            badgeContent={"+5,000원"}
            style={{ cursor: "pointer" }}
            onClick={() => setPoint((p) => parseInt(p || 0) + 5000)}
          />
          <MKBadge
            color="info"
            badgeContent={"+1,000원"}
            style={{ cursor: "pointer" }}
            onClick={() => setPoint((p) => parseInt(p || 0) + 1000)}
          />
        </MKBox>
        <MKBox mt={1}>
          <MKInput
            type="number"
            label="충전 금액"
            placeholder="충전할 금액을 입력해 주세요.(ex 1000)"
            fullWidth
            value={point}
            onInput={(e) => setPoint(e.target.value)}
          />
        </MKBox>
        <MKBox mt={1}>
          <MKTypography variant="h6" textAlign="left">
            1,000원 당 1P 적립
          </MKTypography>
        </MKBox>
        <MKBox
          mt={7}
          py={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            background: "#000000",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => {
            chargePoint();
          }}
        >
          <MKTypography
            component="span"
            variant="h6"
            ml={1}
            style={{
              color: "white",
            }}
          >
            충전 하기
          </MKTypography>
        </MKBox>
      </ReactModal>
    </MKBox>
    // </Grid>
  );
}

export default observer(Sidemenu);
