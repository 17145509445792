import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import bgImage from "assets/images/introduce_bg.png";
import bgImage2 from "assets/images/introduce_bg2.png";
import bgImage3 from "assets/images/introduce_bg3.png";

import check from "assets/images/check.png";

// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { Icon } from "@mui/material";
import Footer from "examples/Footers/Footer";
import footerRoutes from "footer.routes";
import TopMenu from "pages/Componets/topMenu";

const list = [
  "나는 4가지 라인 중 어떤 라인에 속할까?",
  "남들이 보는 나의 이미지는?",
  "나의 히어로 유형은 어떤 유형?",
  "내가 좋아하는 패션 스타일은?",
  "결과 그래프로 더 자세히 들여다보기",
  "성공적인 비즈니스 미팅을 위한 이미지 연출법은?",
  "나를 움직이는 근본 에너지는?",
  "나의 직업 가치관은?",
  "나의 강점은 무엇일까?",
  "나의 생산성을 높여줄 업무환경은?",
  "나의 강점을 강화해줄 미션은?",
  "나의 취약한 업무와 스트레스 증상은?",
  "나의 커뮤니케이션 스타일은?",
  "나의 업무 생산성을 높여줄 행동강령과 업무아이템은?",
  "나의 까똑 및 SNS 스타일은?",
  "현재 나의 회복탄력성 지수와 번아웃 원인은?",
  "나의 커뮤니케이션 능력 키우는 특급 비법",
  "",
];

const list2 = [
  "내가 가진 위대함을 확인해보세요",
  "자기다움 사용설명서를 통해 내가 가진 위대함을 강화하는 습관을 만들어 보세요",
  "가장 생산성이 높은 환경을 만들어보세요",
  "회복탄력성 진단을 통해 스스로를 돌봐주세요",
  "나와 타인을 이해하고 긍정적인 인관관계를 구축해보세요",
  "1년 추적진단을 통해 ‘진짜 나’를 만나보세요",
];

function Presentation() {
  return (
    <MKBox style={{ backgroundColor: "#fff" }}>
      <TopMenu />
      <MKBox
        minHeight="480px"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover.",
          backgroundPosition: "top",
          display: "flex",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={0}>
            <Grid container item xs={3} lg={3} justifyContent="center" mx="auto" mt={0}>
              <MKTypography variant="h1" color="white" textAlign="left" mb={1}>
                기질진단 소개
              </MKTypography>
              <MKTypography variant="h4" color="white" textAlign="left" mb={1}>
                I Can R.E.A.D yoU!
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="section" py={3} style={{ backgroundColor: "#010101" }}>
        <Container>
          <MKBox textAlign="center" justifyContent="center" mb={10}>
            <MKTypography variant="h3" color="secondary" textAlign="center" mt={15}>
              ICRU기질진단도구란?{" "}
            </MKTypography>
            <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mb={2}>
              <MKBox
                mt={10}
                component="div"
                justifyContent="space-between"
                display="flex"
                sx={{
                  color: "#287361",
                  backgroundColor: "#181717",
                  borderRadius: "4px",
                  padding: "10px 20px 10px 20px",
                  width: "100%",
                }}
              >
                <MKBox style={{ display: "flex" }}>
                  <MKBox component="img" src={check} width={30} height={30} />
                  <MKTypography variant="h6" color="white" textAlign="left" mt={0.5} ml={2}>
                    ICRU: I Can R.E.A.D yoU! 의 약자
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mb={2}>
              <MKBox
                component="div"
                justifyContent="space-between"
                display="flex"
                sx={{
                  color: "#287361",
                  backgroundColor: "#181717",
                  borderRadius: "4px",
                  padding: "10px 20px 10px 20px",
                  width: "100%",
                }}
              >
                <MKBox style={{ display: "flex" }}>
                  <MKBox component="img" src={check} width={30} height={30} />
                  <MKTypography variant="h6" color="white" textAlign="left" mt={0.5} ml={2}>
                    기질과 역량을 진단하는 기질유형 진단도구
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid container item xs={8} lg={8} justifyContent="center" mx="auto">
              <MKBox
                component="div"
                justifyContent="space-between"
                display="flex"
                sx={{
                  color: "#287361",
                  backgroundColor: "#181717",
                  borderRadius: "4px",
                  padding: "10px 20px 10px 20px",
                  width: "100%",
                }}
              >
                <MKBox style={{ display: "flex" }}>
                  <MKBox component="img" src={check} width={30} height={30} />
                  <MKTypography variant="h6" color="white" textAlign="left" mt={0.5} ml={2}>
                    현존하는 79개의 심리유형 진단도구를 최초로 통합한 진단도구
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
      <MKBox
        component="section"
        py={3}
        sx={{
          backgroundColor: "#212024",
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={10}>
          <MKTypography variant="h3" color="secondary" textAlign="center">
            본성은 본질로 부터{" "}
          </MKTypography>
        </Grid>
        <Grid container item xs={5} lg={5} justifyContent="center" mx="auto" mt={12} mb={10}>
          <MKTypography variant="h5" color="secondary" textAlign="center">
            칼 구스타프 융의 4가지 인지기능을 기반으로, 현존하는 79개의 심리유형 진단도구를 최초로
            통합한 기질 유형 진단도구 입니다
          </MKTypography>
        </Grid>
      </MKBox>
      <MKBox
        component="section"
        py={3}
        sx={{
          backgroundColor: "#010101",
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={6} lg={6} justifyContent="center" mx="auto" mt={12}>
          <MKTypography variant="h2" color="secondary" textAlign="center">
            자기다움을 누릴 권리{" "}
          </MKTypography>
        </Grid>
        <Grid container item xs={3} lg={3} justifyContent="center" mx="auto" mt={2}>
          <MKTypography variant="h6" color="white" textAlign="center">
            타고난 기질 프로파일링을 통해 여러분의 자기다움 읽어드립니다.{" "}
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={12}
          justifyContent="center"
          display="flex"
          mx="auto"
          mt={2}
        >
          <MKBox>
            <MKTypography
              variant="h1"
              color="secondary"
              textAlign="center"
              mt={5}
              style={{ zIndex: 1 }}
            >
              01{" "}
            </MKTypography>
            <MKBox
              component="div"
              py={3}
              mt={-4}
              sx={{
                backgroundColor: "#010101",
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
                boxSizing: "border-box",
                background: "rgba(255, 255, 255, 0.06)",
                border: "1px solid rgba(255, 255, 255, 0.24)",
                backdropFilter: "blur(5px)",
                borderRadius: "4px",
                width: "250px",
                padding: "30px",
                paddingRight: "45px",
              }}
            >
              <MKTypography variant="h6" color="white" textAlign="left" mt={3} paddingRight={5}>
                기질 프로파일링을 기반으로.{" "}
              </MKTypography>
              <MKTypography variant="h5" color="secondary" textAlign="left" mt={3}>
                자기성장 솔루션을 제시합니다.{" "}
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKBox>
            <MKTypography
              variant="h1"
              color="secondary"
              textAlign="center"
              mt={5}
              style={{ zIndex: 1 }}
            >
              02{" "}
            </MKTypography>
            <MKBox
              component="div"
              py={3}
              ml={2}
              mt={-4}
              mb={10}
              sx={{
                backgroundColor: "#010101",
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
                boxSizing: "border-box",
                background: "rgba(255, 255, 255, 0.06)",
                border: "1px solid rgba(255, 255, 255, 0.24)",
                backdropFilter: "blur(5px)",
                borderRadius: "4px",
                width: "250px",
                padding: "30px",
                paddingRight: "28px",
              }}
            >
              <MKTypography variant="h6" color="white" textAlign="left" mt={3} paddingRight={5}>
                기질 프로파일링을 기반으로.{" "}
              </MKTypography>
              <MKTypography variant="h5" color="secondary" textAlign="left" mt={3}>
                회복탄력성 정도와
                <br />
                원인을 읽어드립니다.{" "}
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </MKBox>
      <MKBox
        component="section"
        py={3}
        sx={{
          backgroundColor: "#212024",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
          container
          item
          xs={5}
          lg={5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx="auto"
          mt={10}
        >
          <MKTypography variant="h3" color="white" textAlign="center">
            연간 추적진단{" "}
          </MKTypography>
          <MKBox container display="flex" flexDirection="column" justifyContent="center" mx="auto">
            <MKBox
              mt={5}
              width="380px"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 20px",
                gap: "10px",
                background: "#343434",
                borderRadius: "100px",
              }}
            >
              <MKTypography variant="h3" color="secondary" textAlign="center" mt={0.5}>
                <Icon>calendar_month</Icon>
              </MKTypography>
              <MKTypography variant="h5" color="white" textAlign="center">
                연간 추적 진단 : 월 1회 총 12번
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKTypography variant="h5" color="white" textAlign="center" mt={5}>
            월 1회, 총 12번의 진단결과를 추적하여
            <br />
            보다 더 정확하게 자기다움을 읽어드립니다.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
          <MKBox textAlign="center">
            <MKBox component="img" src={bgImage2} width={800} height={800} />
          </MKBox>
        </Grid>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={10}>
          <MKTypography variant="h3" color="white" textAlign="center">
            다양한 방송프로그램에서 활용된
            <br />
            ICRU진단도구
          </MKTypography>
        </Grid>
        <Grid container item xs={5} lg={5} justifyContent="center" mx="auto" mt={5}>
          <MKBox
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 15px",
              gap: "10px",
              background: "#343434",
              borderRadius: "100px",
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              style={{ color: "#92FCCF" }}
              textAlign="center"
            >
              KBS 스페셜 | 김정은의 두얼굴
            </MKTypography>
          </MKBox>
          <MKBox
            ml={2}
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 15px",
              gap: "10px",
              background: "#343434",
              borderRadius: "100px",
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              style={{ color: "#92FCCF" }}
              textAlign="center"
            >
              SBS 뉴스토리 | 남북미 정상 분석
            </MKTypography>
          </MKBox>
          <MKBox
            ml={2}
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 15px",
              gap: "10px",
              background: "#343434",
              borderRadius: "100px",
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              style={{ color: "#92FCCF" }}
              textAlign="center"
            >
              tvN 식스센스2 | 출연진 분석
            </MKTypography>
          </MKBox>
          <MKBox
            ml={2}
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 15px",
              gap: "10px",
              background: "#343434",
              borderRadius: "100px",
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              style={{ color: "#92FCCF" }}
              textAlign="center"
            >
              채널A 이제만나러갑니다 | 북한 주요인물 분석
            </MKTypography>
          </MKBox>
          <MKBox
            ml={2}
            mb={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 15px",
              gap: "10px",
              background: "#343434",
              borderRadius: "100px",
            }}
          >
            <MKTypography
              component="span"
              variant="h6"
              style={{ color: "#92FCCF" }}
              textAlign="center"
            >
              JTBC4 인간이왜그래 | 출연진 분석
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
          <MKBox textAlign="center" mt={10} style={{ position: "absolute" }}>
            <MKBox component="img" src={bgImage3} width={1000} height={600} />
          </MKBox>
        </Grid>
        <MKBox mt={24} mb={21} />
      </MKBox>
      <MKBox
        component="section"
        py={3}
        mt={40}
        sx={{
          backgroundColor: "#fff",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={10} mb={5}>
          <MKTypography variant="h3" color="black" textAlign="center">
            ICRU기질진단은
            <br />
            이런것들을 알려줘요
          </MKTypography>
        </Grid>
        {list.map((item, index) => (
          <MKBox justifyContent="center" alignItems="center" key={"box-" + index}>
            {index % 2 === 0 ? (
              <Grid
                key={index}
                container
                item
                xs={12}
                lg={12}
                justifyContent="space-evnely"
                mx="auto"
                px={20}
              >
                <MKBox
                  mb={2}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "left",
                    padding: "5px 15px",
                    gap: "10px",
                    background: "#FBFBFB",
                    borderRadius: "100px",
                    width: "420px",
                  }}
                >
                  <MKTypography
                    component="span"
                    variant="h6"
                    style={{ color: "#000" }}
                    textAlign="center"
                  >
                    {item}
                  </MKTypography>
                </MKBox>
                <MKBox
                  mb={2}
                  ml={10}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "left",
                    padding: "5px 15px",
                    gap: "10px",
                    background: "#FBFBFB",
                    borderRadius: "100px",
                    width: "420px",
                  }}
                >
                  <MKTypography
                    component="span"
                    variant="h6"
                    style={{ color: "#000" }}
                    textAlign="center"
                  >
                    {list[index + 1]}
                  </MKTypography>
                </MKBox>
              </Grid>
            ) : (
              <></>
            )}
          </MKBox>
        ))}
      </MKBox>
      <MKBox
        component="section"
        py={3}
        mt={0}
        sx={{
          backgroundColor: "#fff",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={10} mb={5}>
          <MKTypography variant="h3" color="black" textAlign="center" mb={5}>
            ICRU기질진단 활용법
          </MKTypography>
          {list2.map((item, index) => (
            <MKBox
              key={index}
              mt={2}
              px={20}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 15px",
                gap: "10px",
                background: "#EFFFF9",
                borderRadius: "4px",
                width: "100%",
                height: "50px",
              }}
            >
              <MKTypography variant="h6" color="black" textAlign="center">
                {item}
              </MKTypography>
            </MKBox>
          ))}
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={0} style={{ backgroundColor: "#03FF99", justifyContent: "center" }}>
        <Footer content={footerRoutes} />
      </MKBox>
    </MKBox>
  );
}

export default Presentation;
