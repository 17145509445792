import Container from "@mui/material/Container";

import MKBox from "components/MKBox";

import Sidemenu from "../Componets/sidemenu";
import { Grid, Icon } from "@mui/material";
import MKTypography from "components/MKTypography";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
import ReportService from "services/ReportService";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "examples/Footers/Footer";
// import footerRoutes from "footer.routes";

import nohistory from "assets/images/nohistory.png";
import ReactModal from "react-modal";
import moment from "moment";
import { useMst } from "models";

const customModalStyles = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "744px",
    height: "508px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};
const customModalStyles2 = {
  overlay: {
    backgroundColor: " rgba(0, 0, 0, 0.4)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
  },
  content: {
    width: "400px",
    height: "508px",
    zIndex: "150",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
    backgroundColor: "white",
    justifyContent: "center",
    overflow: "auto",
  },
};

function Result() {
  const navigate = useNavigate();
  const { user } = useMst();
  const [history, setHistory] = useState([]);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    ReportService.getHistory().then((res) => {
      console.log("history", res);
      setHistory(res.data);
    });
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const handlePopupMessage = (item, reportType = "") => {
    setSelectedItem(item);

    if (reportType === "stress" && !item.stressPayment) {
      setModalOpen2(!modalOpen2);
    } else if (reportType === "" && item.payment === 0) {
      setModalOpen(!modalOpen);
    } else {
      if (reportType === "stress") {
        item.reportType = "stress";
      }
      navigate("/result-detail", { state: { selectedItem: item } });
    }
  };

  const getTypeText = (type) => {
    let bgColor, color, text, stress;
    switch (type.substring(0, 1)) {
      case "R":
        bgColor = "#EEE0FF";
        color = "#9747FF";
        text = "창조가";
        stress = "변화";
        break;
      case "E":
        bgColor = "#E5FFE0";
        color = "#55D15A";
        text = "상담가";
        stress = "사람";
        break;
      case "A":
        bgColor = "#FEE5E5";
        color = "#FB4D4D";
        text = "행동가";
        stress = "성취";
        break;
      case "D":
        bgColor = "#DCF0FF";
        color = "#438CFA";
        text = "분석가";
        stress = "검증";
        break;
    }

    return { bgColor, color, text, stress };
  };

  const payment = (point, reportType) => {
    if (user.point < point) {
      setMessage(true);
      return;
    }

    const temp = { ...selectedItem, payment: point, reportType };

    ReportService.payment(temp).then((res) => {
      setSelectedItem(temp);
      if (res.code === 200) {
        navigate("/result-detail", { state: { selectedItem: temp } });
      }
    });
  };

  return (
    <>
      <TopMenu color="dark" />
      <MKBox
        width="100%"
        sx={{
          pt: 15,
          my: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <ReactModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={customModalStyles}
          ariaHideApp={false}
          contentLabel="Pop up Message"
          shouldCloseOnOverlayClick={true}
        >
          <MKBox
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MKTypography variant="h5">ICRU진단결과 보기</MKTypography>
            <Icon onClick={() => setModalOpen(false)} style={{ cursor: "pointer" }}>
              close
            </Icon>
          </MKBox>
          {selectedItem.type && (
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <MKBox
                mx={"auto"}
                mt={3}
                style={{
                  border: "1px solid #eeeeee",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  borderRadius: "4px",
                }}
                py={1}
                px={1}
              >
                <MKTypography component="span" variant="h6" textAlign="center">
                  ICRU진단결과
                  <MKTypography
                    ml={2}
                    component="span"
                    variant="h6"
                    textAlign="center"
                    style={{ color: "#acacac" }}
                  >
                    {moment(selectedItem.createdAt).format("YYYY.MM.DD")}
                  </MKTypography>
                </MKTypography>
                <Grid container xs={3} justifyContent="center" alignItems="center">
                  <MKTypography component="span" variant="h6" textAlign="center">
                    주요기질
                  </MKTypography>
                  <MKBox
                    ml={1}
                    px={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      background: getTypeText(selectedItem.type).bgColor,
                      borderRadius: "4px",
                    }}
                  >
                    <MKTypography
                      component="span"
                      variant="h6"
                      style={{ color: getTypeText(selectedItem.type).color }}
                      textAlign="center"
                    >
                      {getTypeText(selectedItem.type).text}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </MKBox>
            </Grid>
          )}
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <MKBox
              mx={"auto"}
              mt={3}
              xs={12}
              py={1}
              px={2}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "218px",
                border: "1px solid #eeeeee",
                borderRadius: "4px",
              }}
            >
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  무료
                </MKTypography>
              </MKBox>
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  O P
                </MKTypography>
              </MKBox>
              {["기질안내"].map((item) => (
                <MKBox key={item}>
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      fontSize: "14px",
                      color: "#acacac",
                    }}
                  >
                    <Icon>verified</Icon>
                    <MKBox
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        color: "#acacac",
                      }}
                    >
                      {item}
                    </MKBox>
                  </MKTypography>
                </MKBox>
              ))}
              <MKBox
                mt={17}
                py={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  payment(0, "free");
                }}
              >
                <MKTypography
                  component="span"
                  variant="h6"
                  ml={1}
                  style={{
                    color: "#acacac",
                  }}
                >
                  무료 결과 보기
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox
              mx={"auto"}
              mt={3}
              xs={12}
              py={1}
              px={1}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "218px",
                border: "1px solid #eeeeee",
                borderRadius: "4px",
              }}
            >
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  <Icon>lock</Icon>
                  베이직
                </MKTypography>
              </MKBox>
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  1 P
                </MKTypography>
              </MKBox>
              {["기질안내", "최종유형 안내"].map((item) => (
                <MKBox key={item}>
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      fontSize: "14px",
                      color: "#acacac",
                    }}
                  >
                    <Icon>verified</Icon>
                    <MKBox
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        color: "#acacac",
                      }}
                    >
                      {item}
                    </MKBox>
                  </MKTypography>
                </MKBox>
              ))}
              <MKBox
                mt={13}
                py={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  cursor: "pointer",
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  background: "#000000",
                }}
                onClick={() => {
                  payment(1, "basic");
                }}
              >
                <MKTypography
                  component="span"
                  variant="h6"
                  ml={1}
                  style={{
                    color: "white",
                  }}
                >
                  베이직 결과 보기
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox
              mx={"auto"}
              mt={3}
              xs={12}
              py={1}
              px={1}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "218px",
                border: "1px solid #eeeeee",
                borderRadius: "4px",
              }}
            >
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  <Icon>lock</Icon>
                  프리미엄
                </MKTypography>
              </MKBox>
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  3 P
                </MKTypography>
              </MKBox>
              {["기질안내", "최종유형 안내", "솔루션 안내"].map((item) => (
                <MKBox key={item}>
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      fontSize: "14px",
                      color: "#acacac",
                    }}
                  >
                    <Icon>verified</Icon>
                    <MKBox
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        color: "#acacac",
                      }}
                    >
                      {item}
                    </MKBox>
                  </MKTypography>
                </MKBox>
              ))}
              <MKBox
                mt={9}
                py={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  background: "#000000",
                }}
                onClick={() => {
                  payment(3, "premium");
                }}
              >
                <MKTypography
                  component="span"
                  variant="h6"
                  mlx={1}
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  프리미엄 결과 보기
                </MKTypography>
              </MKBox>
            </MKBox>
            {message && (
              <MKBox mt={3} textAlign="center" display="flex" justifyContent="center">
                <MKTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  color="warning"
                >
                  보유한 포인트가 부족합니다.
                </MKTypography>
                <MKTypography
                  ml={1}
                  component={Link}
                  to={"/point"}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  포인트 충전하기
                  <Icon>arrow_forward_ios</Icon>
                </MKTypography>
              </MKBox>
            )}
          </Grid>
        </ReactModal>
        <ReactModal
          isOpen={modalOpen2}
          onRequestClose={() => setModalOpen2(false)}
          style={customModalStyles2}
          ariaHideApp={false}
          contentLabel="Pop up Message"
          shouldCloseOnOverlayClick={true}
        >
          <MKBox
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MKTypography variant="h5">회복탄력성 진단결과 보기</MKTypography>
            <Icon onClick={() => setModalOpen2(false)} style={{ cursor: "pointer" }}>
              close
            </Icon>
          </MKBox>
          {selectedItem.type && (
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <MKBox
                mx={"auto"}
                mt={3}
                style={{
                  border: "1px solid #eeeeee",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  borderRadius: "4px",
                }}
                py={1}
                px={1}
              >
                <Grid container xs={6} justifyContent="center" alignItems="center">
                  <MKTypography component="span" variant="h6" textAlign="center">
                    회복탄력성 진단결과
                  </MKTypography>
                  <MKTypography
                    ml={2}
                    component="span"
                    variant="h6"
                    textAlign="center"
                    style={{ color: "#acacac" }}
                  >
                    {moment(selectedItem.createdAt).format("YYYY.MM.DD")}
                  </MKTypography>
                </Grid>
                <Grid container xs={4} justifyContent="center" alignItems="center">
                  <MKTypography component="span" variant="h6" textAlign="center">
                    번아웃 원인
                  </MKTypography>
                  <MKBox
                    ml={1}
                    px={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      background: getTypeText(selectedItem.type).bgColor,
                      borderRadius: "4px",
                    }}
                  >
                    <MKTypography
                      component="span"
                      variant="h6"
                      style={{ color: getTypeText(selectedItem.type).color }}
                      textAlign="center"
                    >
                      {getTypeText(selectedItem.type).stress}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </MKBox>
            </Grid>
          )}
          <Grid container xs={12} justifyContent="center" alignItems="center">
            <MKBox
              mx={"auto"}
              mt={3}
              xs={12}
              py={1}
              px={1}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: "100%",
                border: "1px solid #eeeeee",
                borderRadius: "4px",
              }}
            >
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  <Icon>lock</Icon>
                  회복탄력성
                </MKTypography>
              </MKBox>
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  0.3 P
                </MKTypography>
              </MKBox>
              {["회복탄력성 레포트"].map((item) => (
                <MKBox key={item}>
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      fontSize: "14px",
                      color: "#acacac",
                    }}
                  >
                    <Icon>verified</Icon>
                    <MKBox
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        color: "#acacac",
                      }}
                    >
                      {item}
                    </MKBox>
                  </MKTypography>
                </MKBox>
              ))}
              <MKBox
                mt={14}
                py={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  cursor: "pointer",
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  background: "#000000",
                }}
                onClick={() => {
                  payment(0.3, "stress");
                }}
              >
                <MKTypography
                  component="span"
                  variant="h6"
                  ml={1}
                  style={{
                    color: "white",
                  }}
                >
                  회복탄력성 결과 보기
                </MKTypography>
              </MKBox>
            </MKBox>
            {message && (
              <MKBox mt={3} textAlign="center" display="flex" justifyContent="center">
                <MKTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  color="warning"
                >
                  보유한 포인트가 부족합니다.
                </MKTypography>
                <MKTypography
                  ml={1}
                  component={Link}
                  to={"/point"}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  포인트 충전하기
                  <Icon>arrow_forward_ios</Icon>
                </MKTypography>
              </MKBox>
            )}
          </Grid>
        </ReactModal>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            display="flex"
            // flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", my: 0, mx: "auto", px: 0.75 }}
          >
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <Sidemenu />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mx: "auto", minHeight: 900 }}>
              <MKTypography variant="h5">진단결과</MKTypography>
              <MKTypography variant="h6" mt={2}>
                ICRU기질진단 결과
              </MKTypography>
              <Grid
                container
                xs={11}
                spacing={3}
                justifyContent="left"
                alignItems="left"
                sx={{ mx: "auto" }}
                mt={3}
              >
                {history.length === 0 && (
                  <>
                    <MKBox mx={"auto"} mt={8}>
                      <MKBox component="img" src={nohistory} width={180} />
                      <MKBox mt={2}>
                        <MKButton
                          mx={"auto"}
                          variant="gradient"
                          color="dark"
                          style={{ color: "#03FF99" }}
                          fullWidth
                          component={Link}
                          to="/test"
                        >
                          진단하기
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </>
                )}
                {history.map((item) => {
                  return (
                    <Grid item xs={12} lg={3} sx={{ mx: "auto" }} key={item.id}>
                      <RotatingCard>
                        <RotatingCardFront
                          color="#ffffff"
                          description={
                            <>
                              <h4 style={{ color: "black" }}>ICRU기질진단 결과</h4>
                              <h5 style={{ color: "gray" }}>
                                <MKTypography
                                  component="span"
                                  variant="h6"
                                  textAlign="center"
                                  mr={1}
                                >
                                  {item?.payment === 1
                                    ? "베이직"
                                    : item?.payment === 3
                                    ? "프리미엄"
                                    : item?.payment === 0
                                    ? "무료"
                                    : ""}
                                </MKTypography>
                                {moment(item.createdAt).format("YYYY.MM.DD")}
                              </h5>
                              <br />
                              <div style={{ color: "black" }}>
                                <Grid container xs={12} justifyContent="center" alignItems="center">
                                  <MKTypography component="span" variant="h6" textAlign="center">
                                    주요기질
                                  </MKTypography>
                                  <MKBox
                                    ml={1}
                                    px={1}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "10px",
                                      background: getTypeText(item.type).bgColor,
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <MKTypography
                                      component="span"
                                      variant="h6"
                                      style={{ color: getTypeText(item.type).color }}
                                      textAlign="center"
                                    >
                                      {getTypeText(item.type).text}
                                    </MKTypography>
                                  </MKBox>
                                </Grid>
                              </div>
                              <MKBox mt={3} mb={1} textAlign="center">
                                <MKButton color={item?.payment === 0 ? "dark" : "success"}>
                                  자세히 보기
                                </MKButton>
                              </MKBox>
                            </>
                          }
                        />
                        <RotatingCardBack
                          color={getTypeText(item.type).bgColor}
                          description={
                            <>
                              <h4 style={{ color: "black" }}>ICRU기질진단 결과</h4>
                              <h5 style={{ color: "gray" }}>
                                <MKTypography
                                  component="span"
                                  variant="h6"
                                  textAlign="center"
                                  mr={1}
                                >
                                  {item?.payment === 1
                                    ? "베이직"
                                    : item?.payment === 3
                                    ? "프리미엄"
                                    : item?.payment === 0
                                    ? "무료"
                                    : ""}
                                </MKTypography>
                                {moment(item.createdAt).format("YYYY.MM.DD")}
                              </h5>
                              <br />
                              <div style={{ color: "black" }}>
                                <Grid container xs={12} justifyContent="center" alignItems="center">
                                  <MKTypography component="span" variant="h6" textAlign="center">
                                    주요기질
                                  </MKTypography>
                                  <MKBox
                                    ml={1}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "10px",
                                      background: getTypeText(item.type).bgColor,
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <MKTypography
                                      component="span"
                                      variant="h6"
                                      style={{ color: getTypeText(item.type).color }}
                                      textAlign="center"
                                    >
                                      {getTypeText(item.type).text}
                                    </MKTypography>
                                  </MKBox>
                                </Grid>
                              </div>
                              <MKBox mt={3} mb={1} textAlign="center">
                                <MKButton
                                  onClick={async () => {
                                    handlePopupMessage(item);
                                  }}
                                  color={item?.payment === 0 ? "dark" : "success"}
                                >
                                  자세히 보기
                                </MKButton>
                              </MKBox>
                            </>
                          }
                        />
                      </RotatingCard>
                    </Grid>
                  );
                })}
              </Grid>
              <MKBox mt={5}></MKBox>
              <MKTypography variant="h6" mt={2}>
                회복탄력성진단 결과
              </MKTypography>
              <Grid container item xs={11} spacing={3} alignItems="left" sx={{ mx: "auto" }} mt={3}>
                {history.length === 0 && (
                  <>
                    <MKBox mx={"auto"} mt={8}>
                      <MKBox component="img" src={nohistory} width={180} />
                      <MKBox mt={2}>
                        <MKButton
                          mx={"auto"}
                          variant="gradient"
                          color="dark"
                          style={{ color: "#03FF99" }}
                          fullWidth
                          component={Link}
                          to="/test"
                        >
                          진단하기
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </>
                )}
                {history.map((item) => {
                  return (
                    <Grid item xs={12} lg={3} sx={{ mx: "auto" }} key={item.id}>
                      <RotatingCard>
                        <RotatingCardFront
                          color="#ffffff"
                          description={
                            <>
                              <h4 style={{ color: "black" }}>회복탄력성진단 결과</h4>
                              <h5 style={{ color: "gray" }}>
                                <MKTypography
                                  component="span"
                                  variant="h6"
                                  textAlign="center"
                                  mr={1}
                                >
                                  {item?.payment === 1
                                    ? "베이직"
                                    : item?.payment === 3
                                    ? "프리미엄"
                                    : item?.payment === 0
                                    ? "무료"
                                    : ""}
                                </MKTypography>
                                {moment(item.createdAt).format("YYYY.MM.DD")}
                              </h5>
                              <br />
                              <div style={{ color: "black" }}>
                                <Grid container xs={12} justifyContent="center" alignItems="center">
                                  <MKTypography component="span" variant="h6" textAlign="center">
                                    번아웃 원인
                                  </MKTypography>
                                  <MKBox
                                    ml={1}
                                    px={1}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "10px",
                                      background: getTypeText(item.type).bgColor,
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <MKTypography
                                      component="span"
                                      variant="h6"
                                      style={{ color: getTypeText(item.type).color }}
                                      textAlign="center"
                                    >
                                      {getTypeText(item.type).stress}
                                    </MKTypography>
                                  </MKBox>
                                </Grid>
                              </div>
                              <MKBox mt={3} mb={1} textAlign="center">
                                <MKButton
                                  onClick={async () => {
                                    handlePopupMessage(item, "stress");
                                  }}
                                  color={!item?.stressPayment ? "dark" : "success"}
                                >
                                  자세히 보기
                                </MKButton>
                              </MKBox>
                            </>
                          }
                        />
                        <RotatingCardBack
                          color={getTypeText(item.type).bgColor}
                          description={
                            <>
                              <h4 style={{ color: "black" }}>회복탄력성진단 결과</h4>
                              <h5 style={{ color: "gray" }}>
                                <MKTypography
                                  component="span"
                                  variant="h6"
                                  textAlign="center"
                                  mr={1}
                                >
                                  {item?.payment === 1
                                    ? "베이직"
                                    : item?.payment === 3
                                    ? "프리미엄"
                                    : item?.payment === 0
                                    ? "무료"
                                    : ""}
                                </MKTypography>
                                {moment(item.createdAt).format("YYYY.MM.DD")}
                              </h5>
                              <br />
                              <div style={{ color: "black" }}>
                                <Grid container xs={12} justifyContent="center" alignItems="center">
                                  <MKTypography component="span" variant="h6" textAlign="center">
                                    번아웃 원인
                                  </MKTypography>
                                  <MKBox
                                    ml={1}
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      gap: "10px",
                                      background: getTypeText(item.type).bgColor,
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <MKTypography
                                      component="span"
                                      variant="h6"
                                      style={{ color: getTypeText(item.type).color }}
                                      textAlign="center"
                                    >
                                      {getTypeText(item.type).stress}
                                    </MKTypography>
                                  </MKBox>
                                </Grid>
                              </div>
                              <MKBox mt={3} mb={1} textAlign="center">
                                <MKButton
                                  onClick={async () => {
                                    handlePopupMessage(item, "stress");
                                  }}
                                  color={!item?.stressPayment ? "dark" : "success"}
                                >
                                  자세히 보기
                                </MKButton>
                              </MKBox>
                            </>
                          }
                        />
                      </RotatingCard>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {/* <Footer content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default Result;
