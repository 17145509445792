import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/test_bg.png";
import cardImage from "assets/images/test_card.svg";
import MKButton from "components/MKButton";
import { Link, useNavigate } from "react-router-dom";
import { getItem } from "utils/localstorage";
import TopMenu from "pages/Componets/topMenu";
import { useMst } from "models";
import { useEffect } from "react";
import { toast } from "react-toastify";

const code = new URL(window.location.href).searchParams.get("code");
console.log(code)

function Presentation() {
  const navigate = useNavigate()
  const { user } = useMst();
  return (
    <>
      <TopMenu />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
            <MKTypography variant="h3" color="secondary" textAlign="center" mb={5}>
              ICRU 기질진단
            </MKTypography>
          </Grid>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
            <MKTypography
              variant="h6"
              color="white"
              textAlign="center"
              mb={1}
              style={{
                fontFamily: "Pretendard",
              }}
            >
              지금부터 ICRU기질진단을 시작합니다.{""}
              <br />총 18항목으로 구성되어 있으며 소요 시간은 약 10분입니다.{""}
              <br />
              모든 질문과 결과에는 좋고 나쁨이 없습니다.{""}
              <br />
              스스로에게 솔직하게 답변해 주세요.{""}
            </MKTypography>
          </Grid>
          <Grid container item xs={8} lg={6} justifyContent="center" mx="auto" mt={5}>
            <MKBox component="img" src={cardImage} alt="brand" width={300} />
          </Grid>
          <Grid container item xs={8} lg={6} justifyContent="center" mx="auto" mt={5}>
            {user.type === "company" && (
              <>
                <MKTypography variant="h5" color="secondary" textAlign="center" mb={5}>
                  ICRU 기질진단은 개인 계정으로만 가능합니다.
                </MKTypography>
                  <MKButton
                  variant="gradient"
                  color="success"
                  size="large"
                  component={Link}
                  to={"/team"}
                  sx={{ mb: 2 }}
                  style={{
                    background: "#03FF99",
                    borderRadius: "81.6801px",
                    fontFamily: "Pretendard",
                    fonttyle: "normal",
                    fontWeight: 500,
                    fontSize: "17.9696px",
                    color: "#004952",
                    width: "243px",
                  }}
                >
                  관리 페이지 이동
                </MKButton>
              </>
            )}
            {user.type !== "company" && (
              <MKButton
                variant="gradient"
                color="success"
                size="large"
                onClick={() => {
                  if (code) {
                    navigate('/user-info', { state: { code } })
                  } else {
                    toast.error("승인 받지 않은 사용자 입니다.");
                  }
                }}
                sx={{ mb: 2 }}
                style={{
                  background: "#03FF99",
                  borderRadius: "81.6801px",
                  fontFamily: "Pretendard",
                  fonttyle: "normal",
                  fontWeight: 500,
                  fontSize: "17.9696px",
                  color: "#004952",
                  width: "243px",
                }}
              >
                진단 시작
              </MKButton>
            )}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Presentation;
