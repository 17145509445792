import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import routes from "routes";
import brandImage from "assets/images/brand_dark.png";
import { useEffect } from "react";
import axios from "axios";
import { setItem } from "utils/localstorage";
import jwtDecode from "jwt-decode";
import config from "../../../config";
import qs from "qs";
import AuthService from "services/AuthService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMst } from "../../../models";

const SignInBasic = () => {
  const navigate = useNavigate();
  const { user } = useMst();

  console.log("config", config);

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    const state = new URL(window.location.href).searchParams.get("state");
    console.log("code", code);
    console.log("state", state);

    if (code) {
      if (state === "kakao") {
        axios({
          url: "https://kauth.kakao.com/oauth/token",
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          data: {
            grant_type: "authorization_code",
            client_id: config.KAKAO_API_KEY,
            redirect_uri: window.location.origin + "/callback",
            code: code,
            state: state,
          },
        }).then((res) => {
          setItem("kakao-access-token", JSON.stringify(res.data));
          console.log(res.data.id_token);
          const decoded = jwtDecode(res.data.id_token);
          console.log("decoded", decoded);
          console.log("res", res);

          axios({
            url: "https://kapi.kakao.com/v2/user/me",
            method: "GET",
            headers: {
              Authorization: `Bearer ${res.data.access_token}`,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }).then(async (res) => {
            console.log("res", res.data.kakao_account.email);
            const isExistEmail = await AuthService.isExistEmail(res.data.kakao_account.email);
            if (!isExistEmail.data) {
              navigate("/sign-up", { state: { kakao: res.data } });
            } else {
              AuthService.snsLogin({
                type: "kakao",
                data: res.data,
              }).then((res) => {
                console.log("login", res);
                if (res.code === 200 && res.data?.id) {
                  AuthService.authMe().then((res) => {
                    console.log("me", res);
                    if (res.code === 200 && res.data?.id) {
                      user.setData(res.data);
                      toast.success("로그인에 성공하였습니다.");
                      navigate("/intro");
                    }
                  });
                }
              });
            }
          });
        });
      } else if (state === "naver") {
        // window.location.href = `https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=V11kc2eeuYNkWde6p5H8&client_secret=D5EU53Ni8d&code=${code}&state=${state}`;
        axios({
          url: "https://nid.naver.com/oauth2.0/token",
          method: "POST",
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            "X-Naver-Client-Id": config.NAVER_API_KEY,
            "X-Naver-Client-Secret": config.NAVER_SECRET,
          },
          data: qs.stringify({
            grant_type: "authorization_code",
            client_id: config.NAVER_API_KEY,
            client_secret: config.NAVER_SECRET,
            code: code,
            state: state,
          }),
        }).then((res) => {
          console.log("res", res);
        });
      }
    }
  }, []);

  return (
    <>
      <DefaultNavbar
        brandImage={brandImage}
        routes={routes}
        action={[
          {
            type: "internal",
            route: "/sign-in",
            label: "로그인",
            color: "dark",
          },
          {
            type: "internal",
            route: "/sign-up",
            label: "회원가입",
            color: "dark",
          },
        ]}
        transparent={false}
        center
      />
      <MKBox position="absolute" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}></MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter />
      </MKBox>
    </>
  );
};

export default SignInBasic;
