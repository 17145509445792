/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/intro.mp4";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import TopMenu from "pages/Componets/topMenu";
import { useMst } from "models";
import { useEffect } from "react";

function Presentation() {
  const { user } = useMst();

  useEffect(() => {
    console.log("user", user.me);
    console.log("user", user.independent);
  }, []);

  return (
    <>
      <video
        id="myvideo"
        autoPlay
        muted
        loop
        style={{
          backgroundColor: "black",
          width: "100%",
          height: "100%",
          position: "fixed",
          left: 0,
          bottom: 0,
          minWdth: "100%",
          minHeight: "100%",
        }}
      >
        <source src={bgImage} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <TopMenu />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          // backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={0}>
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              style={{
                fontFamily: "Pretendard",
              }}
            >
              YOU ARE GREATER
            </MKTypography>
            <MKTypography
              variant="h1"
              color="white"
              textAlign="center"
              mb={1}
              style={{
                fontFamily: "Pretendard",
              }}
            >
              THAN YOU EXPECT.
            </MKTypography>
          </Grid>
          <Grid container item xs={8} lg={8} justifyContent="center" mx="auto" mt={5}>
            <MKButton
              variant="gradient"
              color="success"
              size="large"
              component={Link}
              to={user.independent ? "/test" : "/home"}
              sx={{ mb: 2 }}
              style={{
                background: "#03FF99",
                borderRadius: "81.6801px",
                fontFamily: "Pretendard",
                fonttyle: "normal",
                fontWeight: 500,
                fontSize: "17.9696px",
                color: "#004952",
              }}
            >
              Enter
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Presentation;
