import Container from "@mui/material/Container";

import MKBox from "components/MKBox";

import Sidemenu from "../Componets/sidemenu";
import { Grid, Icon } from "@mui/material";
import MKTypography from "components/MKTypography";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
// import ReportService from "services/ReportService";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Footer from "examples/Footers/Footer";
// import footerRoutes from "footer.routes";

import moment from "moment";
import ReactModal from "react-modal";
import ReportService from "services/ReportService";
import { useMst } from "models";
import { getItem } from "utils/localstorage";
import { toast } from "react-toastify";
import config from "../../config";

function ResultDetail() {
  const { user } = useMst();

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState({ type: "R" });
  const [type, setType] = useState("free");
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const typeList = {
    free: {
      title: "무료",
      list: ["기질안내"],
    },
    basic: {
      title: "베이직",
      list: ["기질안내", "최종유형 안내"],
    },
    premium: {
      title: "프리미엄",
      list: ["기질안내", "최종유형 안내", "솔루션 안내"],
    },
    stress: {
      title: "회복탄력성",
      list: ["회복탄력성 레포트"],
    },
  };

  const customModalStyles = {
    overlay: {
      backgroundColor: " rgba(0, 0, 0, 0.4)",
      width: "100%",
      height: "100vh",
      zIndex: "10",
      position: "fixed",
      top: "0",
      left: "0",
    },
    content: {
      width: type === "free" ? "550px" : "450px",
      height: "550px",
      zIndex: "150",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
      backgroundColor: "white",
      justifyContent: "center",
      overflow: "auto",
    },
  };

  useEffect(() => {
    if (!location.state?.selectedItem) {
      navigate("/result");
      return;
    }
    setSelectedItem(location.state.selectedItem);
  }, []);

  useEffect(() => {
    if (user.independent) {
      setType("premium");
    } else {
      setType(selectedItem.reportType || "free");
    }
  }, [selectedItem]);

  const getTypeText = (type) => {
    let bgColor, color, text, stress;
    switch (type.substring(0, 1)) {
      case "R":
        bgColor = "#EEE0FF";
        color = "#9747FF";
        text = "창조가";
        stress = "변화";
        break;
      case "E":
        bgColor = "#E5FFE0";
        color = "#55D15A";
        text = "상담가";
        stress = "사람";
        break;
      case "A":
        bgColor = "#FEE5E5";
        color = "#FB4D4D";
        text = "행동가";
        stress = "성취";
        break;
      case "D":
        bgColor = "#DCF0FF";
        color = "#438CFA";
        text = "분석가";
        stress = "검증";
        break;
    }

    return { bgColor, color, text, stress };
  };

  const payment = (point, reportType) => {
    if (user.point < point) {
      setMessage(true);
      return;
    }

    const temp = { ...selectedItem, payment: point, reportType };
    ReportService.payment(temp).then((res) => {
      if (res.code === 200) {
        setSelectedItem(temp);
        setModalOpen(false);
      }
    });
  };

  const download = async () => {
    // ReportService.download(user.id, selectedItem.id).then((res) => {
    setLoading(true);
    const response = await fetch(
      config.API_URL + "/download/" + user.id + "/" + selectedItem.id + "/" + type,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "client-id": "kr.co.icru",
          "user-id": user.id,
          authorization: "Bearer " + getItem("access-token"),
        },
      }
    );

    if (response) {
      const blob = await response.blob();
      const fileName =
        user.name + (type === "stress" ? " 회복탄력성 레포트.pdf" : " 기질진단 레포트.pdf");

      // 가상 링크 DOM 만들어서 다운로드 실행
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("파일다운로드를 성공하였습니다.");
    } else {
      toast.error("파일다운로드를 실패하였습니다.");
    }
    // });
    setLoading(false);
  };

  const sendmail = () => {
    setLoading(true);
    ReportService.sendmail({
      userId: user.id,
      historyId: selectedItem.id,
      type: "",
      reportType: type,
    }).then((res) => {
      if (res.code === 200) {
        toast.success("이메일 전송에 성공하였습니다.");
      } else {
        toast.error("이메일 전송에 실패하였습니다.");
      }
      setLoading(false);
    });
  };

  return (
    <>
      <TopMenu color="dark" loading={loading} />
      <MKBox
        width="100%"
        sx={{
          pt: 15,
          my: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <ReactModal
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={customModalStyles}
          ariaHideApp={false}
          contentLabel="Pop up Message"
          shouldCloseOnOverlayClick={true}
        >
          <MKBox
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MKTypography variant="h5">ICRU진단결과 보기</MKTypography>
            <Icon onClick={() => setModalOpen(false)} style={{ cursor: "pointer" }}>
              close
            </Icon>
          </MKBox>
          {selectedItem.type && (
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <MKBox
                mx={"auto"}
                mt={3}
                style={{
                  border: "1px solid #eeeeee",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  borderRadius: "4px",
                }}
                py={1}
                px={1}
              >
                <MKTypography component="span" variant="h6" textAlign="center">
                  ICRU진단결과
                  <MKTypography
                    ml={2}
                    component="span"
                    variant="h6"
                    textAlign="center"
                    style={{ color: "#acacac" }}
                  >
                    {moment(selectedItem.createdAt).format("YYYY.MM.DD")}
                  </MKTypography>
                </MKTypography>
                <Grid container xs={5} justifyContent="center" alignItems="center">
                  <MKTypography component="span" variant="h6" textAlign="center">
                    주요기질
                  </MKTypography>
                  <MKBox
                    ml={1}
                    px={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      background: getTypeText(selectedItem.type).bgColor,
                      borderRadius: "4px",
                    }}
                  >
                    <MKTypography
                      component="span"
                      variant="h6"
                      style={{ color: getTypeText(selectedItem.type).color }}
                      textAlign="center"
                    >
                      {getTypeText(selectedItem.type).text}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </MKBox>
            </Grid>
          )}
          <Grid container xs={12} justifyContent="center" alignItems="center">
            {type === "free" && (
              <MKBox
                mx={"auto"}
                mt={3}
                xs={12}
                py={1}
                px={1}
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "218px",
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                }}
              >
                <MKBox>
                  <MKTypography component="span" variant="h6" textAlign="center">
                    <Icon>lock</Icon>
                    베이직
                  </MKTypography>
                </MKBox>
                <MKBox>
                  <MKTypography component="span" variant="h6" textAlign="center">
                    1 P
                  </MKTypography>
                </MKBox>
                {["기질안내", "최종유형 안내"].map((item) => (
                  <MKBox key={item}>
                    <MKTypography
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        fontSize: "14px",
                        color: "#acacac",
                      }}
                    >
                      <Icon>verified</Icon>
                      <MKBox
                        component="span"
                        variant="h6"
                        ml={1}
                        style={{
                          color: "#acacac",
                        }}
                      >
                        {item}
                      </MKBox>
                    </MKTypography>
                  </MKBox>
                ))}
                <MKBox
                  mt={13}
                  py={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    cursor: "pointer",
                    border: "1px solid #eeeeee",
                    borderRadius: "4px",
                    background: "#000000",
                  }}
                  onClick={() => {
                    payment(1, "basic");
                  }}
                >
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      color: "white",
                    }}
                  >
                    베이직 결과 보기
                  </MKTypography>
                </MKBox>
              </MKBox>
            )}
            <MKBox
              mx={"auto"}
              mt={3}
              xs={12}
              py={1}
              px={1}
              container
              justifyContent="center"
              alignItems="center"
              style={{
                width: type === "free" ? "218px" : "100%",
                border: "1px solid #eeeeee",
                borderRadius: "4px",
              }}
            >
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  <Icon>lock</Icon>
                  프리미엄
                </MKTypography>
              </MKBox>
              <MKBox>
                <MKTypography component="span" variant="h6" textAlign="center">
                  {type === "free" ? 3 : 2} P
                </MKTypography>
              </MKBox>
              {["기질안내", "최종유형 안내", "솔루션 안내"].map((item) => (
                <MKBox key={item}>
                  <MKTypography
                    component="span"
                    variant="h6"
                    ml={1}
                    style={{
                      fontSize: "14px",
                      color: "#acacac",
                    }}
                  >
                    <Icon>verified</Icon>
                    <MKBox
                      component="span"
                      variant="h6"
                      ml={1}
                      style={{
                        color: "#acacac",
                      }}
                    >
                      {item}
                    </MKBox>
                  </MKTypography>
                </MKBox>
              ))}
              <MKBox
                mt={9}
                py={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  border: "1px solid #eeeeee",
                  borderRadius: "4px",
                  background: "#000000",
                }}
                onClick={() => {
                  payment(type === "free" ? 3 : 2, "premium");
                }}
              >
                <MKTypography
                  component="span"
                  variant="h6"
                  mlx={1}
                  style={{
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  프리미엄 결과 보기
                </MKTypography>
              </MKBox>
            </MKBox>
            {message && (
              <MKBox mt={3} textAlign="center" display="flex" justifyContent="center">
                <MKTypography
                  component="span"
                  variant="button"
                  fontWeight="regular"
                  color="warning"
                >
                  보유한 포인트가 부족합니다.
                </MKTypography>
                <MKTypography
                  ml={1}
                  component={Link}
                  to={"/point"}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  포인트 충전하기
                  <Icon>arrow_forward_ios</Icon>
                </MKTypography>
              </MKBox>
            )}
          </Grid>
        </ReactModal>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            display="flex"
            // flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", my: 0, mx: "auto", px: 0.75 }}
          >
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <Sidemenu />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mx: "auto", minHeight: 900 }}>
              <MKTypography variant="h5">진단결과</MKTypography>
              {/* <MKTypography variant="h6" mt={2}>
                {type === "stress" ? "회복탄력성 진단결과" : "ICRU기질진단 결과"}
              </MKTypography> */}
              {selectedItem && (
                <Grid item xs={12} lg={12} sx={{ mx: "auto" }}>
                  <MKBox
                    mx={"auto"}
                    mt={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      borderRadius: "4px",
                    }}
                    py={1}
                    px={1}
                  >
                    <MKTypography component="span" variant="h6" textAlign="center">
                      {type === "stress" ? "회복탄력성진단 결과" : "ICRU기질진단 결과"}
                      <MKTypography
                        ml={2}
                        component="span"
                        variant="h6"
                        textAlign="center"
                        style={{ color: "#acacac" }}
                      >
                        {moment(selectedItem.createdAt).format("YYYY.MM.DD")}
                      </MKTypography>
                    </MKTypography>
                    <Grid container xs={2} justifyContent="center" alignItems="center">
                      <MKTypography component="span" variant="h6" textAlign="center">
                        번아웃 원인
                      </MKTypography>
                      <MKBox
                        ml={1}
                        px={1}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          background: getTypeText(selectedItem.type).bgColor,
                          borderRadius: "4px",
                        }}
                      >
                        <MKTypography
                          component="span"
                          variant="h6"
                          style={{ color: getTypeText(selectedItem.type).color }}
                          textAlign="center"
                        >
                          {getTypeText(selectedItem.type).stress}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  </MKBox>
                </Grid>
              )}
              <Grid container xs={12} justifyContent="center" alignItems="center">
                <MKBox
                  mx={"auto"}
                  mt={3}
                  xs={12}
                  py={1}
                  px={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: "100%",
                    border: "1px solid #eeeeee",
                    borderRadius: "4px",
                  }}
                >
                  <MKBox>
                    <MKTypography component="span" variant="h6" textAlign="center">
                      {typeList[type].title}
                    </MKTypography>
                  </MKBox>
                  {typeList[type].list.map((item) => (
                    <MKBox key={item}>
                      <MKTypography
                        component="span"
                        variant="h6"
                        ml={1}
                        style={{
                          fontSize: "14px",
                          color: "#acacac",
                        }}
                      >
                        <Icon>verified</Icon>
                        <MKBox
                          component="span"
                          variant="h6"
                          ml={1}
                          style={{
                            color: "#acacac",
                          }}
                        >
                          {item}
                        </MKBox>
                      </MKTypography>
                    </MKBox>
                  ))}
                  <Grid container xs={12} justifyContent="left" alignItems="left">
                    {type !== "stress" && (
                      <MKBox
                        mt={9}
                        py={1}
                        px={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          border: "1px solid #eeeeee",
                          borderRadius: "4px",
                          background: "#000000",
                        }}
                        onClick={() => {
                          navigate("/report", { state: { result: selectedItem } });
                        }}
                      >
                        <MKTypography
                          component="span"
                          variant="h6"
                          mlx={1}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          무료결과 페이지
                        </MKTypography>
                      </MKBox>
                    )}
                    {type !== "free" && (
                      <MKBox
                        mt={9}
                        py={1}
                        px={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          border: "1px solid #eeeeee",
                          borderRadius: "4px",
                          background: "#000000",
                        }}
                        onClick={download}
                      >
                        <MKTypography
                          component="span"
                          variant="h6"
                          mlx={1}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          PDF 다운로드
                        </MKTypography>
                      </MKBox>
                    )}
                    {type !== "free" && (
                      <MKBox
                        mt={9}
                        py={1}
                        px={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          border: "1px solid #eeeeee",
                          borderRadius: "4px",
                          background: "#000000",
                        }}
                        onClick={sendmail}
                      >
                        <MKTypography
                          component="span"
                          variant="h6"
                          mlx={1}
                          style={{
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          이메일 전송
                        </MKTypography>
                      </MKBox>
                    )}
                  </Grid>
                </MKBox>
                <MKBox container>
                  {type !== "premium" && type !== "stress" && (
                    <>
                      <MKBox mt={3} textAlign="center" justifyContent="center">
                        <MKTypography component="span" variant="button" fontWeight="regular">
                          더 자세한 결과가 궁금하신가요?
                        </MKTypography>
                      </MKBox>
                      <MKBox
                        mt={1}
                        textAlign="center"
                        justifyContent="center"
                        style={{ backgroundColor: "#CDFFEA" }}
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        <MKTypography
                          ml={1}
                          component={Link}
                          variant="button"
                          fontWeight="medium"
                          textGradient
                          style={{ color: "#287361" }}
                        >
                          진단 결과 업그레이드
                        </MKTypography>
                      </MKBox>
                    </>
                  )}
                  <MKBox
                    mt={1}
                    textAlign="center"
                    justifyContent="center"
                    onClick={() => navigate("/result")}
                  >
                    <MKTypography
                      ml={1}
                      component={Link}
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      목록으로 돌아가기
                      <Icon>arrow_forward_ios</Icon>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {/* <Footer content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default ResultDetail;
