import { types } from "mobx-state-tree";
import Questions from "./Questions";
import Answer from "./Answer";

export const Config = types
  .model("Step", {
    testStep: types.maybe(types.number),
    openStep: types.maybe(types.number),
    questions: types.maybe(types.array(Questions), []),
    answer: types.maybe(types.array(Answer), []),
    nowQuestionNumber: types.maybe(types.number),
    code: types.maybe(types.string),
    type: types.maybe(types.string),
    reportType: types.maybe(types.string),
    groupName: types.maybe(types.string),
    lang: types.optional(types.string, "ko"),
    ownerId: types.maybe(types.number),
    loading: types.maybe(types.boolean),
  })
  .views((self) => ({
    get getData() {
      return { ...self };
    },
    get getType() {
      return self.type;
    },
    get getCode() {
      return self.code;
    },
    get getReportType() {
      return self.reportType;
    },
    get getGroupName() {
      return self.groupName;
    },
    get showQuestions() {
      return [...self.questions.toJSON()];
    },
    get showAnswer() {
      return [...self.answer.toJSON()];
    },
  }))
  .actions((self) => ({
    async setLoading(l) {
      self.loading = l;
    },
    async setTestStep(step) {
      self.testStep = step;
    },
    async setLang(lang) {
      self.lang = lang;
    },
    async setReportType(reportType) {
      self.reportType = reportType;
    },
    async setGroupName(groupName) {
      self.groupName = groupName;
    },
    async setOpenStep(step) {
      self.openStep = step;
    },
    async setQuestions(questions) {
      self.questions = questions;
    },
    async setAnswerInit(answer) {
      self.answer = answer;
    },
    async setAnswer(order, answer) {
      self.answer[order].setData(answer);
    },
    async setNowQuestionNumber(number) {
      self.nowQuestionNumber = number;
    },
    async setData(data) {
      Object.keys(data).forEach((d) => (self[d] = data[d]));
    },
    async reset() {
      self.testStep = 0;
      self.openStep = 0;
      self.questions = [];
      self.answer = [];
      self.nowQuestionNumber = 0;
      self.code = "";
      self.type = "";
      self.reportType = "";
      self.groupName = "";
      self.ownerId = 0;
      self.loading = false;
    },
  }));
export default Config;
