import Container from "@mui/material/Container";

import MKBox from "components/MKBox";

import Sidemenu from "../Componets/sidemenu";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import TopMenu from "pages/Componets/topMenu";
import { useEffect } from "react";
import { toast } from "react-toastify";
// import Footer from "examples/Footers/Footer";
// import footerRoutes from "footer.routes";

var params = {};

location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
  params[key] = value;
});

function Presentation() {
  useEffect(() => {
    console.log("point", params);

    if (params?.state === 1) {
      toast.success("충전이 완료되었습니다.");
    }
  }, []);

  return (
    <>
      <TopMenu color="dark" />
      <MKBox
        width="100%"
        sx={{
          pt: 15,
          my: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            display="flex"
            // flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", my: 0, mx: "auto", px: 0.75 }}
          >
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <Sidemenu />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mx: "auto", minHeight: 900 }}>
              <MKTypography variant="h5">포인트 현황</MKTypography>
              <MKTypography variant="h6" mt={2}></MKTypography>
              <Grid
                container
                item
                xs={11}
                spacing={3}
                alignItems="left"
                sx={{ mx: "auto" }}
                mt={3}
              ></Grid>
            </Grid>
          </Grid>
        </Container>
        {/* <Footer content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default Presentation;
