let host =
  window.location.host.indexOf("localhost") > -1 || window.location.host.indexOf("127.0.0.1") > -1
    ? "local"
    : window.location.host.replace("icru.co.kr", "").replace("dev.", "").replace("openknowl.", "").replace("gimhae.", "");
if (!host) host = "prod";
// host = 'prod'
console.log("host", host);

const config = {
  local: {
    CLIENT_ID: "kr.co.icru",
    API_URL: "http://localhost:31011/api/v2",
    KAKAO_API_KEY: "1bfb303c2ccc13f402f8e289c9b92b8b",
    NAVER_API_KEY: "V11kc2eeuYNkWde6p5H8",
    NAVER_SECRET: "D5EU53Ni8d",
    REDIRECT_URI: "http://localhost:3000",
  },
  dev: {
    CLIENT_ID: "kr.co.icru",
    API_URL: "https://dev.icru.co.kr/api/v2",
    KAKAO_API_KEY: "1bfb303c2ccc13f402f8e289c9b92b8b",
    NAVER_API_KEY: "V11kc2eeuYNkWde6p5H8",
    NAVER_SECRET: "D5EU53Ni8d",
    REDIRECT_URI: "https://dev.icru.co.kr",
  },
  prod: {
    CLIENT_ID: "kr.co.icru",
    API_URL: "https://dev.icru.co.kr/api/v2",
    KAKAO_API_KEY: "1bfb303c2ccc13f402f8e289c9b92b8b",
    NAVER_API_KEY: "V11kc2eeuYNkWde6p5H8",
    NAVER_SECRET: "D5EU53Ni8d",
    REDIRECT_URI: "https://dev.icru.co.kr",
  },
};

export default config[host];
