import { APIClient } from "../utils/apiHelper";

const { get, post } = new APIClient();

export default {
  signUp: (signUpData) => post(`/oauth/signup`, signUpData),
  startUp: (data) => post(`/oauth/startup`, data),
  login: (loginData) => post(`/oauth/login`, loginData),
  snsLogin: (loginData) => post(`/oauth/login/sns`, loginData),
  isExistUserId: (userId) => get(`/oauth/isExistUserId?userId=${userId}`),
  isExistEmail: (email) => get(`/oauth/isExistEmail?email=${email}`),
  userEmail: (email) => post(`/user/email`, { email }),
  recovery: (email) => post(`/history/recovery`, { email }),
  authMe: () => get(`/oauth/me`),
  modifyUser: (modifyData) => post(`/user/modify`, modifyData),
};
