import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import brandImage from "assets/images/brand.png";
import brandImageDark from "assets/images/brand_dark.png";
import { getItem } from "utils/localstorage";
import { observer } from "mobx-react";
import { useMst } from "models";
import AuthService from "services/AuthService";
import { useEffect } from "react";

const TopMenu = observer(({ color = "white", loading = false }) => {
  const { user } = useMst();

  useEffect(() => {
    AuthService.authMe().then((res) => {
      // console.log("me", res);
      if (res?.code === 200 && res?.data?.id) {
        user.setData(res.data);
      }
    });
  }, []);

  const accessToken = getItem("access-token");
  if (!accessToken) {
    return (
      <DefaultNavbar
        loading={loading}
        brandImage={color !== "dark" ? brandImage : brandImageDark}
        routes={[]}
        action={[
          // {
          //   type: "internal",
          //   route: "/sign-in",
          //   label: "로그인",
          //   color: color,
          // },
          // {
          //   type: "internal",
          //   route: "/sign-up",
          //   label: "회원가입",
          //   color: color,
          // },
        ]}
        light={color !== "dark" ? true : false}
        transparent={color !== "dark" ? true : false}
        center
      />
    );
  } else {
    return (
      <DefaultNavbar
        loading={loading}
        brandImage={color !== "dark" ? brandImage : brandImageDark}
        routes={[]}
        action={[
          {
            type: "internal",
            route: "/mypage",
            label: user.name + "님" + (user.userId ? " (" + user.userId + ")" : ""),
            color: color !== "dark" ? "secondary" : "warning",
          },
          {
            type: "internal",
            route: "/sign-out",
            label: "로그아웃",
            color: color,
          },
        ]}
        light={color !== "dark" ? true : false}
        transparent
        center
      />
    );
  }
});

export default TopMenu;
