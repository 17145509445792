import axios from "axios";
import config from "../config";
import { getItem, removeItem, setItem } from "./localstorage";
import jwtDecode from "jwt-decode";

// 2022.10.19 axios 통신 progress
let progress = 0;
let timerId = null;

function setProgress(value) {
  progress = value;
  try {
    window.progressbar?.onChange(progress);
  } catch (e) {
    console.log(e);
  }
  // console.log(progress, window);
}

function timer() {
  if (progress < 98) {
    const diff = 100 - progress;
    const inc = diff / (10 + progress * (1 + progress / 100));
    setProgress(progress + inc);
  }
  timerId = setTimeout(timer, 50);
}

console.log("config.API_URL", config.API_URL);
const axiosApi = axios.create({
  baseURL: config.API_URL,
  timeout: 0,
});
// intercepting to capture errors
axiosApi.interceptors.request.use(
  (request) => {
    setProgress(0);
    timer();

    request.headers["Content-Type"] = "application/json";
    request.headers["client-id"] = config.CLIENT_ID;

    const accessToken = getItem("access-token");
    const refreshToken = getItem("refresh-token");

    if (accessToken && refreshToken) {
      let tokenData;
      try {
        tokenData = jwtDecode(accessToken);
        request.headers["authorization"] = "Bearer " + accessToken;
        request.headers["user-id"] = tokenData.id;
      } catch (e) {
        console.log(e);
      }

      if (!tokenData) {
        try {
          tokenData = jwtDecode(refreshToken);
          request.headers["authorization"] = "Bearer " + tokenData.accessToken;
          request.headers["user-id"] = tokenData.id;
        } catch (e) {
          console.log(e);
        }
      }
    }

    return request;
  },
  (error) => console.log(error)
);

axiosApi.interceptors.response.use(
  (response) => {
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
    setProgress(100);

    if (response.data.result || response.status === 200 || response.status === 206) {
      if (!response.headers["content-type"].includes("application/json")) {
        console.log("download header");
        return response;
      }

      if (response.headers["access-token"])
        setItem("access-token", response.headers["access-token"]);
      if (response.headers["refresh-token"])
        setItem("refresh-token", response.headers["refresh-token"]);
      return response.data;
    } else {
      //console.log("response>>", response)
      let message;
      switch (response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        /* case 910:
          message = "아이디 또는 비밀번호가 틀렸습니다."
          break */
        default:
          message = response.data.error || response.data.data.error;
      }
      //console.log("message>>", message)
      return Promise.reject(message);
    }
  },
  (error) => {
    if (error.response) {
      //console.log(error)
      if (error.response.data && error.response.data.message) {
        if (error.response.data.code === 910) {
          return error.response.data;
        }
        //console.log('!!!')
        //console.log("error.response.data", error.response.data)
        if (error.response.data.code === 401 /*  || error.response.data.code === 500 */) {
          //removeItem('access-token')
          //removeItem('refresh-token')
          endSession();
        }
      }
    }
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
);

const axiosApi2 = axios.create({
  baseURL: config.API_URL,
  timeout: 0,
});

axiosApi2.interceptors.request.use(
  (request) => {
    setProgress(0);
    timer();

    request.headers["Content-Type"] = "multipart/form-data";
    request.headers["client-id"] = config.CLIENT_ID;

    const accessToken = getItem("access-token");
    const refreshToken = getItem("refresh-token");

    if (accessToken && refreshToken) {
      let tokenData;
      try {
        tokenData = jwtDecode(accessToken);
        request.headers["authorization"] = "Bearer " + accessToken;
        request.headers["user-id"] = tokenData.id;
      } catch (e) {
        console.log(e);
      }

      if (!tokenData) {
        try {
          tokenData = jwtDecode(refreshToken);
          request.headers["authorization"] = "Bearer " + tokenData.accessToken;
          request.headers["user-id"] = tokenData.id;
        } catch (e) {
          console.log(e);
        }
      }
    }

    return request;
  },
  (error) => console.log(error)
);

axiosApi2.interceptors.response.use(
  (response) => {
    if (timerId) {
      clearTimeout(timerId);
      timerId = null;
    }
    setProgress(100);

    if (response.data.result || response.status === 200) {
      if (response.headers["access-token"])
        setItem("access-token", response.headers["access-token"]);
      if (response.headers["refresh-token"])
        setItem("refresh-token", response.headers["refresh-token"]);
      return response.data;
    } else {
      //console.log("response>>", response)
      let message;
      switch (response.status) {
        case 500:
          message = "Internal Server Error";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        /* case 910:
          message = "아이디 또는 비밀번호가 틀렸습니다."
          break */
        default:
          message = response.data.error || response.data.data.error;
      }
      //console.log("message>>", message)
      return Promise.reject(message);
    }
  },
  (error) => {
    if (error.response) {
      //console.log(error)
      if (error.response.data && error.response.data.message) {
        if (error.response.data.code === 910) {
          return error.response.data;
        }
        //console.log('!!!')
        //console.log("error.response.data", error.response.data)
        if (error.response.data.code === 401 /*  || error.response.data.code === 500 */) {
          //removeItem('access-token')
          //removeItem('refresh-token')
          endSession();
        }
      }
    }
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
);

const endSession = async () => {
  await Promise.all([
    removeItem("access-token"),
    removeItem("refresh-token"),
    removeItem("rootState"),
    removeItem("webToken"),
    removeItem("authUser"),
    //user.logout(),
    //company.logout(),
  ]).then(() => {
    //document.location.href(`/${demoPages.login.path}`);
  });
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => axiosApi.get(url, params);

  /**
   * post given data to url
   */
  post = (url, data) => axiosApi.post(url, data);

  /**
   * Updates data
   */
  put = (url, data) => axiosApi.put(url, data);

  /**
   * Delete
   */
  remove = (url) => axiosApi.delete(url);

  /**
   * File
   */
  post2 = (url, data) => axiosApi2.post(url, data);
  put2 = (url, data) => axiosApi2.put(url, data);
}

export { APIClient };
