import { types } from "mobx-state-tree";
import Options from "./Options";

export const Answer = types
  .model("Answer", {
    type: types.maybe(types.string),
    answerType: types.maybe(types.string),
    title: types.maybe(types.string),
    userId: types.maybe(types.number),
    questionsId: types.maybe(types.number),
    answer: types.maybe(types.array(Options)),
    options: types.maybe(types.array(Options)),
    createdAt: types.maybe(types.string),
    udpatedAt: types.maybe(types.string),
  })
  .views((self) => ({
    get show() {
      return { ...self };
    },
  }))
  .actions((self) => ({
    async setData(data) {
      Object.keys(data).forEach((d) => {
        self[d] = data[d];
      });
    },
    async reset() {
      self.type = "";
      self.answerType = "";
      self.title = "";
      self.userId = -1;
      self.questionsId = -1;
      self.answer = [];
      self.options = [];
      self.createdAt = "";
      self.udpatedAt = "";
    },
  }));

export default Answer;
