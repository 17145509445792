/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import brandImage from "assets/images/brand_dark.png";

// import KakaoIcon from "assets/images/kakao_icon.png";
// import NaverIcon from "assets/images/naver_icon.png";
import AuthService from "services/AuthService";
import { useEffect, useState } from "react";
// import config from "../../../config";
import { toast } from "react-toastify";
import { useMst } from "../../../models";
import { getItem } from "utils/localstorage";

const SignInBasic = () => {
  const { user } = useMst();

  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const accessToken = getItem("access-token");
    if (accessToken) {
      navigate("/intro");
    }
  }, []);

  const loginHandler = () => {
    console.log("login", userId, password);
    AuthService.login({
      userId: userId,
      password: password,
    }).then((res) => {
      console.log("login", res);
      if (res.code === 200 && res.data?.id) {
        AuthService.authMe().then((res) => {
          console.log("me", res);
          if (res?.code === 200 && res?.data?.id) {
            user.setData(res.data);
            toast.success("로그인에 성공하였습니다.");
            navigate("/team");
          }
        });
      } else {
        toast.error("로그인에 실패하였습니다.");
      }
    });
  };

  return (
    <>
      <DefaultNavbar
        brandImage={brandImage}
        routes={[]}
        action={[]}
        transparent={false}
        center
      />
      <MKBox position="absolute" top={0} left={0} zIndex={1} width="100%" minHeight="100vh" />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox mx={2} p={2} mb={1} textAlign="center">
                <MKTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
                  로그인
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput
                      type="email"
                      label="id"
                      fullWidth
                      value={userId}
                      onInput={(e) => setUserId(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          loginHandler();
                        }
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      value={password}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="dark"
                      style={{ color: "#03FF99" }}
                      fullWidth
                      onClick={loginHandler}
                    >
                      로그인
                    </MKButton>
                  </MKBox>
                  {/* <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography
                      mx={1}
                      component={Link}
                      to="#"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      아이디 비밀번호 찾기
                    </MKTypography>
                    |
                    <MKTypography
                      mx={1}
                      component={Link}
                      to="/sign-up"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      회원가입
                    </MKTypography>
                  </MKBox> */}
                </MKBox>
              </MKBox>
              {/* <MKBox textAlign="center">
                <MKTypography variant="button" color="dark" fontWeight="medium" textGradient>
                  SNS 간편 로그인
                </MKTypography>
              </MKBox> */}
              <MKBox p={2} mb={1} justifyContent="space-between" textAlign="center">
                {/* <MKBox
                  mx={1}
                  opacity={1}
                  component="a"
                  href="#"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://kauth.kakao.com/oauth/authorize?client_id=" +
                      config.KAKAO_API_KEY +
                      "&response_type=code&scope=openid profile_nickname profile_image account_email name age_range phone_number" +
                      "&state=kakao&redirect_uri=" +
                      window.location.origin +
                      "/callback";
                  }}
                >
                  <MKBox component="img" src={KakaoIcon} />
                </MKBox> */}
                {/* <MKBox
                  mx={1}
                  opacity={1}
                  component="a"
                  href="#"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" +
                      config.NAVER_API_KEY +
                      "&state=naver&redirect_uri=" +
                      window.location.origin +
                      "/callback";
                  }}
                >
                  <MKBox component="img" src={NaverIcon} />
                </MKBox> */}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
        <SimpleFooter />
      </MKBox>
    </>
  );
};

export default SignInBasic;
