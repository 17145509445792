import Container from "@mui/material/Container";

import MKBox from "components/MKBox";

import Sidemenu from "../Componets/sidemenu";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import TopMenu from "pages/Componets/topMenu";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MKInput from "components/MKInput";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
// import Footer from "examples/Footers/Footer";
// import footerRoutes from "footer.routes";
import { useMst } from "models";
import ReportService from "services/ReportService";
import { useNavigate } from "react-router-dom";

var params = {};

location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
  params[key] = value;
});

function Team() {
  const navigate = useNavigate();
  const { user } = useMst();
  const [team, setTeam] = useState("");
  const [positions, setPositions] = useState([]);
  const [position, setPosition] = useState("");

  const [links, setLinks] = useState([]);

  useEffect(() => {
    console.log(window.location);
    ReportService.teamList({ companyId: user.me.id }).then((res) => {
      console.log("res", res);
      let temp = [];
      res.data.map((team) => {
        temp.push(team.teamName);
      });
      setLinks(res.data);
    });
    ReportService.rankList({ companyId: user.me.id }).then((res) => {
      console.log("res", res);
      let temp = [];
      res.data.map((team) => {
        temp.push(team.teamName);
      });
      setPositions(res.data);
    });
  }, []);

  return (
    <>
      <TopMenu color="dark" />
      <MKBox
        width="100%"
        sx={{
          pt: 15,
          my: 0,
          backgroundColor: "#ffffff",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={3}
            c
            // flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", my: 0, mx: "auto", px: 0.75 }}
          >
            <Grid item xs={12} lg={2} sx={{ mx: "auto" }}>
              <Sidemenu />
            </Grid>
            <Grid item xs={12} lg={10} sx={{ mx: "auto" }} mb={20}>
              <MKTypography variant="h5">팀 생성</MKTypography>
              <Grid container item xs={11} spacing={3} alignItems="left" sx={{ mx: "auto" }} mt={3}>
                <MKInput
                  type="text"
                  name="team"
                  label="팀 / 학과 / 프로젝트 명을 입력해주세요. (Enter 등록)"
                  fullWidth
                  value={team}
                  onInput={(e) => {
                    setTeam(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setTeam("");
                      if (links.filter((team) => team.teamName === e.target.value).length > 0) {
                        toast.error("이미 등록된 팀입니다.");
                      } else {
                        ReportService.teamCreate({
                          companyId: user.me.id,
                          teamName: e.target.value,
                        }).then((res) => {
                          setLinks([...links, res.data]);
                          console.log("res", res);
                        });
                      }
                    }
                  }}
                />
                {links.map((team, i) => (
                  <MKBox key={"team-" + i}>
                    <MKBadge
                      sx={{ ml: 1, my: 1, backgroundColor: "#E6F8FA", radius: 2 }}
                      badgeContent={team.teamName}
                      container
                    />
                    <MKButton
                      iconOnly={true}
                      sx={{ ml: -2, my: 1, padding: 0 }}
                      onClick={() => {
                        ReportService.teamRemove(team).then((res) => {
                          setLinks(res.data);
                          console.log("res", res);
                        });
                      }}
                    >
                      <MKBadge
                        sx={{ backgroundColor: "#E6F8FA", radius: 2 }}
                        badgeContent={"X"}
                        container
                      />
                    </MKButton>
                  </MKBox>
                ))}
              </Grid>
              {/* <MKTypography variant="h5" mt={5}>
                직책 생성
              </MKTypography>
              <Grid container item xs={11} spacing={3} alignItems="left" sx={{ mx: "auto" }} mt={3}>
                <MKInput
                  type="text"
                  name="position"
                  label="직책을 입력해주세요. (Enter 등록)"
                  fullWidth
                  value={position}
                  onInput={(e) => {
                    setPosition(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setPosition("");
                      if (
                        positions.filter((position) => position.rankName === e.target.value)
                          .length > 0
                      ) {
                        toast.error("이미 등록된 팀입니다.");
                      } else {
                        ReportService.rankCreate({
                          companyId: user.me.id,
                          rankName: e.target.value,
                        }).then((res) => {
                          setPositions([...positions, res.data]);
                          console.log("res", res);
                        });
                      }
                    }
                  }}
                />
                {positions.map((position, i) => (
                  <MKBox key={"position-" + i}>
                    <MKBadge
                      sx={{ ml: 1, my: 1, backgroundColor: "#E6F8FA", radius: 2 }}
                      badgeContent={position.rankName}
                      container
                    />
                    <MKButton
                      iconOnly={true}
                      sx={{ ml: -2, my: 1, padding: 0 }}
                      onClick={() => {
                        ReportService.rankRemove(position).then((res) => {
                          setPositions(res.data);
                          console.log("res", res);
                        });
                      }}
                    >
                      <MKBadge
                        sx={{ backgroundColor: "#E6F8FA", radius: 2 }}
                        badgeContent={"X"}
                        container
                      />
                    </MKButton>
                  </MKBox>
                ))}
              </Grid> */}
              <MKTypography variant="h5" mt={5}>
                팀 관리
              </MKTypography>
              <MKBox
                fullWidths
                alignItems="center"
                justifyContent="center"
                display="flex"
                sx={{ backgroundColor: "#E6F8FA" }}
                px={2}
                py={1}
                mt={2}
              >
                <MKTypography sx={{ color: "#397E87", fontSize: 15 }}>
                  [링크]를 통해 팀원들에게 가입 링크를 전달할 수 있습니다.
                </MKTypography>
              </MKBox>
              {/* <MKBox
                fullWidths
                alignItems="right"
                justifyContent="right"
                display="flex"
                px={2}
                py={1}
                mt={2}
              >
                <MKInput placeholder="팀 / 프로젝트명"></MKInput>
              </MKBox> */}
              <MKBox>
                {links.map((team, i) => (
                  <MKBox mt={2} key={"team-" + i}>
                    <MKTypography variant="h6">{team.teamName}</MKTypography>
                    <MKBox mt={1} display="flex" alignItems="center" justifyContent="center">
                      <MKInput
                        fullWidth
                        disabled
                        value={window.location.origin + "/?code=" + team.code}
                      ></MKInput>
                      <MKBox ml={1}></MKBox>
                      <MKButton
                        sx={{ height: 45, width: 170, backgroundColor: "#EFEFEF" }}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(window.location.origin + "/?code=" + team.code)
                            .then(() => {
                              toast.success("가입 링크가 복사되었습니다!");
                            });
                        }}
                      >
                        가입 링크 복사
                      </MKButton>
                      <MKBox ml={1}></MKBox>
                      <MKButton
                        sx={{ height: 45, width: 110, backgroundColor: "#EFEFEF" }}
                        onClick={() => {
                          navigate("/tracking", { state: team });
                        }}
                      >
                        팀 현황
                      </MKButton>
                    </MKBox>
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          </Grid>
        </Container>
        {/* <Footer content={footerRoutes} /> */}
      </MKBox>
    </>
  );
}

export default Team;
